import React, { useState } from 'react';
import useCourseDetail from '../../../hooks/useCourseDetail';
import '../../../css/course.css';
import { Link } from 'react-router-dom';
import {
  formatReadableDate,
  getRandomImage,
  secondsToHoursAndMinutes,
} from '../../../hooks/helpers';
import Courseenrollmentbutton from '../../../components/Courseenrollmentbutton';
import './CourseDetailViewContent.css';
import IblSpinLoader from '../../../loaders/iblSpinLoader';

const CourseDetailViewContent = ({ courseID }) => {
  const [
    courseInfo,
    courseInfoLoaded,
    enrollmentStatus,
    enrollmentStatusLoaded,
  ] = useCourseDetail(courseID);
  const [activeOption, setActiveOption] = useState('about');
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [openSections, setOpenSections] = useState([]);

  console.log(courseInfo, 'here is the course info loaded here');
  const handleImageError = (e) => {
    e.target.src = getRandomImage();
  };
  const imageUrl =
    process.env.REACT_APP_IBL_LMS_URL + courseInfo?.course_image_asset_path;

  const handleAccordionItemClick = (sectionName) => {
    setOpenSections((prevOpenSections) => {
      if (prevOpenSections.includes(sectionName)) {
        return prevOpenSections.filter((name) => name !== sectionName);
      } else {
        return [...prevOpenSections, sectionName];
      }
    });
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const handleShowAllClick = () => {
    setIsContentVisible(!isContentVisible);
  };
  const parseSections = (overview) => {
    const sections = overview?.split('</section>');
    return sections?.map((section, index) =>
      index !== 0 ? (
        <div key={index} dangerouslySetInnerHTML={{ __html: section }} />
      ) : null
    );
  };

  return (
    <>
      {!courseInfoLoaded ? (
        <IblSpinLoader size={40} containerHeight={'400px'} />
      ) : (
        <div className="about-body-2">
          <div className="about-cont-2">
            <div className="about-grid">
              <div className="left-col-3">
                <div
                  className="about-box-2"
                  id="w-node-adb8c6bd-8ff9-86ea-d38f-f1efe5481473-c66baadf"
                >
                  <div className="div-block-539">
                    <div className={'course-detail-name-block'}>
                      <div className="text-block-310">
                        {courseInfo?.display_name}
                      </div>
                      <div
                        className="text-block-310"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {courseInfo?.subject || ''}
                      </div>
                      <div className="div-block-543 hide">
                        <div className="div-block-544 ins">
                          <img
                            alt=""
                            className="image-185"
                            src="images/warren-wong-VVEwJJRRHgk-unsplash.jpg"
                          />
                        </div>
                        <div className="div-block-528">
                          <div className="text-block-317">Eliott Hanson</div>
                        </div>
                      </div>
                    </div>
                    <div className="div-block-533 hide">
                      <div>Offered By</div>
                      <img
                        alt=""
                        className="image-8"
                        loading="lazy"
                        src="images/log_d.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="about-box-2 desktop-hidden">
                  <div className="div-block-536">
                    <div className="vid-header-3 w-clearfix">
                      <img
                        src={imageUrl}
                        onError={handleImageError}
                        alt="course image"
                        className="video w-video w-embed c-image"
                      />

                      {enrollmentStatusLoaded && (
                        <Courseenrollmentbutton
                          enrollmentStatus={enrollmentStatus}
                          coursePrice={courseInfo.course_price}
                          courseID={courseID}
                          courseName={courseInfo?.display_name}
                        />
                      )}
                    </div>
                    <div className="div-block-537">
                      {courseInfo.course_price !== '' && (
                        <div className="div-block-535">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="/images/coin_1coin.png"
                          />
                          <div className="text-block-311">
                            {courseInfo.course_price}
                          </div>
                        </div>
                      )}

                      <div className="div-block-535">
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="/images/language_1language.png"
                        />
                        <div className="text-block-311">
                          {courseInfo?.language}
                        </div>
                      </div>
                      <div className="div-block-535">
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="/images/certificate_1certificate.png"
                        />
                        <div className="text-block-311">Certificate</div>
                      </div>
                      {courseInfo?.duration !== '' && (
                        <div className="div-block-535">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="/images/clock_3clock.png"
                          />
                          <div className="text-block-311">
                            {typeof courseInfo.duration === 'string'
                              ? courseInfo.duration
                              : secondsToHoursAndMinutes(courseInfo.duration)}
                          </div>
                        </div>
                      )}

                      {courseInfo?.start_date !== '' && (
                        <div className="div-block-535">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="/images/date_1date.png"
                          />
                          <div className="text-block-311">
                            {formatReadableDate(courseInfo.start_date)}
                          </div>
                        </div>
                      )}

                      <div
                        className="div-block-535"
                        style={{ display: 'none' }}
                      >
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="/images/63b5a1e8664d9617e9b5018f_level_icon_163b5a1e8664d9617e9b5018f_level_icon.png"
                        />
                        <div className="text-block-311">Beginner</div>
                      </div>

                      <div
                        className="div-block-535"
                        style={{ display: 'none' }}
                      >
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="/images/cc_1cc.png"
                        />
                        <div className="text-block-311">
                          Arabic, French, Portuguese, Italian, German, Russian,
                          English, Spanish, Korean, Japanese
                        </div>
                      </div>
                      <div
                        className="div-block-535 last"
                        style={{ display: 'none' }}
                      >
                        <div className="div-block-527">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="/images/share_3share.png"
                          />
                          <div className="text-block-311">
                            Share this course
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="about-box-2 mt20"
                  id="w-node-adb8c6bd-8ff9-86ea-d38f-f1efe54814ac-c66baadf"
                >
                  <div className="options-3">
                    <div className="div-block-530">
                      <div
                        className={`link-options-2 ${activeOption === 'about' ? 'active' : ''}`}
                        data-name="about"
                        onClick={() => handleOptionClick('about')}
                      >
                        About
                      </div>
                      {/*TODO : connect the instructors panel to real data*/}
                      <div
                        className="link-options-2"
                        data-name="instructors"
                        style={{ display: 'none' }}
                      >
                        Instructors
                      </div>
                      <div
                        className={`link-options-2 ${activeOption === 'syllabus' ? 'active' : ''}`}
                        onClick={() => handleOptionClick('syllabus')}
                      >
                        Syllabus
                      </div>
                    </div>
                  </div>
                  <div className="panels-3">
                    {activeOption === 'about' && (
                      <>
                        <div className="panel active" data-name="about">
                          <div
                            style={{
                              maxHeight: isContentVisible ? 'unset' : '298px',
                              overflow: 'hidden',
                            }}
                          >
                            <div className="text1">
                              <section>
                                <h2>Course Description</h2>
                                <p
                                  id="courseDescription"
                                  style={{ lineHeight: '28px' }}
                                  className={'faq-content-2 text-2-content'}
                                  dangerouslySetInnerHTML={{
                                    __html: String(
                                      courseInfo.description
                                    ).replace(/(?:\r\n|\r|\n)/g, '<br/>'),
                                  }}
                                ></p>
                              </section>
                            </div>
                            <div className="faq-question-wrap">
                              <div className="faq-content-2 text-2-content">
                                {parseSections(courseInfo.overview)}
                                <p />
                              </div>
                            </div>
                          </div>
                        </div>
                        <Link
                          data-w-id="5ec90528-139f-819b-62fc-cf8a97299e64"
                          to="#"
                          onClick={() => handleShowAllClick()}
                          className="faq-question-bar w-inline-block show_all_cont"
                          style={{
                            marginBottom: isContentVisible ? '30px' : 'unset',
                          }}
                        >
                          <div>
                            <div
                              className="question-title"
                              style={{ color: '#528ac1' }}
                            >
                              {isContentVisible ? 'Hide all' : 'Show all'}
                            </div>
                          </div>
                        </Link>
                      </>
                    )}

                    {activeOption === 'syllabus' && (
                      <div className="panel active" data-name="syllabus">
                        <div className="about-4" id="about">
                          <div className="text-block-319">
                            Syllabus
                            <br />
                          </div>
                          <div
                            className="accordion js-accordion"
                            data-ix="fade-up-1"
                          >
                            {courseInfo?.course_outline?.map(
                              (section, index) => (
                                <div className="accordion-item-7 f" key={index}>
                                  <div
                                    className="accordion__title-2"
                                    onClick={() =>
                                      handleAccordionItemClick(section.name)
                                    }
                                    data-w-id={`accordion-title-${index}`}
                                  >
                                    <div className="text-block-318">
                                      {section.name}
                                    </div>
                                    <img
                                      alt=""
                                      className={`image-188 right ${openSections.includes(section.name) ? 'hiden' : ''}`}
                                      src="/images/course_plus.png"
                                    />
                                    <img
                                      alt=""
                                      className={`image-188 right ${openSections.includes(section.name) ? '' : 'hidden'}`}
                                      src="/images/course_minus.png"
                                      width={18}
                                    />
                                  </div>
                                  <div
                                    className="accordion__content-wrap-3"
                                    style={{
                                      height: openSections.includes(
                                        section.name
                                      )
                                        ? 'auto'
                                        : '0px',
                                    }}
                                  >
                                    <div className="accordion__content-4">
                                      {section.children.map(
                                        (lesson, lessonIndex) => (
                                          <div
                                            className="div-block-541 f"
                                            key={lessonIndex}
                                          >
                                            <div className="div-block-542">
                                              <img
                                                alt=""
                                                className="image-188"
                                                src="/images/course_play.png"
                                              />
                                            </div>
                                            <div className="div-block-534">
                                              <div className="text-block-315 visited">
                                                {lesson.name}
                                              </div>
                                            </div>
                                            <div className="div-block-529"></div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="panel"
                      data-name="instructors"
                      style={{ display: 'none' }}
                    >
                      <div className="about-4" id="about">
                        <div className="text-block-319">
                          Instructors
                          <br />
                        </div>
                        <div className="instructor-block-3">
                          <div className="inst-block-2">
                            <img
                              alt=""
                              className="instructor-img-2"
                              src="images/warren-wong-VVEwJJRRHgk-unsplash.jpg"
                            />
                            <div className="div-block-526">
                              <div className="instructor-name-2">
                                {' '}
                                Eliott Hanson, Phd
                              </div>
                              <div className="instructor-subtitle-2">
                                Computer Science Professor
                              </div>
                              <div className="instructor-school-3">
                                School of Computer Science
                              </div>
                            </div>
                          </div>
                          <div className="inst-block-2 s">
                            <img
                              alt=""
                              className="instructor-img-2"
                              src="images/t6.jpg"
                            />
                            <div className="div-block-526">
                              <div className="instructor-name-2">Jean Long</div>
                              <div className="instructor-subtitle-2">
                                Computer Science Professor
                              </div>
                              <div className="instructor-school-3">
                                School of Computer Science
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="panel"
                      data-name="reviews"
                      style={{ display: 'none' }}
                    >
                      <div className="about-4" id="about">
                        <div className="text-block-319">
                          Reviews
                          <br />
                        </div>
                        <div
                          className="slider-11 desktop w-slider"
                          data-animation="slide"
                          data-autoplay="false"
                          data-autoplay-limit={0}
                          data-delay={4000}
                          data-disable-swipe="false"
                          data-duration={500}
                          data-easing="ease"
                          data-hide-arrows="true"
                          data-infinite="true"
                          data-nav-spacing={3}
                        >
                          <div className="w-slider-mask">
                            <div className="w-slide">
                              <div className="div-block-532">
                                <div className="div-block-531 test-block x">
                                  <div className="image_author-4">
                                    <img
                                      alt=""
                                      className="image-187"
                                      src="images/636cf1dea11595d85363c94e_74.jpeg"
                                    />
                                    <div className="test-name-5">
                                      Inez Sierra
                                    </div>
                                  </div>
                                  <div className="test-text-2">
                                    <div className="text-block-313">
                                      "It has been an amazing journey. I learnt
                                      a lot from it and I will go through
                                      different topics especially, functions and
                                      OOPS to have a better understanding."
                                    </div>
                                  </div>
                                </div>
                                <div className="div-block-531 test-block x">
                                  <div className="image_author-4">
                                    <img
                                      alt=""
                                      className="image-187"
                                      src="images/636cf04fa8ca6a7c565f8fe8_52.jpeg"
                                    />
                                    <div className="test-name-5">
                                      Emir O'Sullivan
                                    </div>
                                  </div>
                                  <div className="test-text-2">
                                    <div className="text-block-313">
                                      "My personal experience was incredible
                                      because the tutor made everything
                                      understandable super easily. I am very
                                      grateful to Jose for providing such
                                      amazing content."
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-slide">
                              <div className="div-block-532">
                                <div className="div-block-531 test-block x">
                                  <div className="image_author-4">
                                    <img
                                      alt=""
                                      className="image-187"
                                      src="images/636cf063baa75730ac1aeb84_33.jpeg"
                                    />
                                    <div className="test-name-5">
                                      Aiden Rayner
                                    </div>
                                  </div>
                                  <div className="test-text-2">
                                    <div className="text-block-313">
                                      "I learn so much thing from this Course.
                                      Even though there is still so many things
                                      to learn about Python, this course is
                                      really helpful for a beginner like me."
                                    </div>
                                  </div>
                                </div>
                                <div className="div-block-531 test-block x">
                                  <div className="image_author-4">
                                    <img
                                      alt=""
                                      className="image-187"
                                      src="images/6372581e0cdf4f9183b84397_54.jpeg"
                                    />
                                    <div className="test-name-5">
                                      Jodie Reese
                                    </div>
                                  </div>
                                  <div className="test-text-2">
                                    <div className="text-block-313">
                                      "Took this course to gain more confidence
                                      in Python. The Instructor is very good at
                                      explaining and course materials are well
                                      thought out and flows well. Highly
                                      Recommend!"
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="left-arrow-8 w-slider-arrow-left">
                            <img
                              alt=""
                              className="image-189"
                              src="images/back.svg"
                            />
                          </div>
                          <div className="right-arrow-7 w-slider-arrow-right">
                            <img
                              alt=""
                              className="image-ra-2"
                              src="images/next.svg"
                            />
                          </div>
                          <div className="slide-nav-8 w-slider-nav w-round"></div>
                        </div>
                        <div
                          className="slider-12 mob w-slider"
                          data-animation="slide"
                          data-autoplay="false"
                          data-autoplay-limit={0}
                          data-delay={4000}
                          data-disable-swipe="false"
                          data-duration={500}
                          data-easing="ease"
                          data-hide-arrows="true"
                          data-infinite="true"
                          data-nav-spacing={3}
                        >
                          <div className="mask-5 w-slider-mask">
                            <div className="w-slide">
                              <div className="div-block-532">
                                <div className="div-block-531 test-block m">
                                  <div className="image_author-4">
                                    <img
                                      alt=""
                                      className="image-187"
                                      src="images/636cf1dea11595d85363c94e_74.jpeg"
                                    />
                                    <div className="test-name-5">
                                      Inez Sierra
                                    </div>
                                  </div>
                                  <div className="test-text-2">
                                    <div className="text-block-313">
                                      "It has been an amazing journey. I learnt
                                      a lot from it and I will go through
                                      different topics especially, functions and
                                      OOPS to have a better understanding."
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-slide">
                              <div className="div-block-532">
                                <div className="div-block-531 test-block m">
                                  <div className="image_author-4">
                                    <img
                                      alt=""
                                      className="image-187"
                                      src="images/636cf04fa8ca6a7c565f8fe8_52.jpeg"
                                    />
                                    <div className="test-name-5">
                                      Emir O'Sullivan
                                    </div>
                                  </div>
                                  <div className="test-text-2">
                                    <div className="text-block-313">
                                      "It has been an amazing journey. I learnt
                                      a lot from it and I will go through
                                      different topics especially, functions and
                                      OOPS to have a better understanding."
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="left-arrow-8 w-slider-arrow-left">
                            <img
                              alt=""
                              className="image-189"
                              src="images/back.svg"
                            />
                          </div>
                          <div className="right-arrow-7 w-slider-arrow-right">
                            <img
                              alt=""
                              className="image-ra-2"
                              src="images/next.svg"
                            />
                          </div>
                          <div className="slide-nav-9 w-slider-nav w-round"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="panel"
                      data-name="faq"
                      style={{ display: 'none' }}
                    >
                      <div className="about-4" id="about">
                        <div className="text-block-319">
                          Frequently Asked Questions
                          <br />
                        </div>
                        <div
                          className="accordion js-accordion"
                          data-ix="fade-up-1"
                        >
                          <div className="accordion-item-6">
                            <div
                              className="accordion__title__faq-2 first"
                              data-w-id="adb8c6bd-8ff9-86ea-d38f-f1efe54816b7"
                            >
                              <div className="accordion__plus-wrapper-faq-2">
                                <img
                                  alt=""
                                  src="images/63b6e31b4cbb39101fa8cea7_right-arrow_163b6e31b4cbb39101fa8cea7_right-arrow.png"
                                />
                              </div>
                              <div className="text-block-320">
                                When will I have access to the lectures and
                                assignments?
                              </div>
                            </div>
                            <div
                              className="accordion__content-wrap-4"
                              style={{ height: '0PX' }}
                            >
                              <div className="accordion__content_faq">
                                <div className="faq__block-4 last">
                                  <div className="div-block-40-faq">
                                    <div className="text-block-313">
                                      Once you enroll for a Certificate, you’ll
                                      have access to all videos, quizzes, and
                                      programming assignments (if applicable).
                                      Peer review assignments can only be
                                      submitted and reviewed once your session
                                      has begun. If you choose to explore the
                                      course without purchasing, you may not be
                                      able to access certain assignments.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item-6">
                            <div
                              className="accordion__title__faq-2"
                              data-w-id="adb8c6bd-8ff9-86ea-d38f-f1efe54816c3"
                            >
                              <div className="accordion__plus-wrapper-faq-2">
                                <img
                                  alt=""
                                  src="images/63b6e31b4cbb39101fa8cea7_right-arrow_163b6e31b4cbb39101fa8cea7_right-arrow.png"
                                />
                              </div>
                              <div className="text-block-320">
                                What will I get if I subscribe to this
                                Specialization?
                              </div>
                            </div>
                            <div
                              className="accordion__content-wrap-4"
                              style={{ height: '0PX' }}
                            >
                              <div className="accordion__content_faq">
                                <div className="faq__block-4">
                                  <div className="div-block-40-faq">
                                    <div className="text-block-313">
                                      When you enroll in the course, you get
                                      access to all of the courses in the
                                      Specialization, and you earn a certificate
                                      when you complete the work. Your
                                      electronic Certificate will be added to
                                      your Accomplishments page - from there,
                                      you can print your Certificate or add it
                                      to your LinkedIn profile. If you only want
                                      to read and view the course content, you
                                      can audit the course for free.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item-6">
                            <div
                              className="accordion__title__faq-2"
                              data-w-id="adb8c6bd-8ff9-86ea-d38f-f1efe54816cf"
                            >
                              <div className="accordion__plus-wrapper-faq-2">
                                <img
                                  alt=""
                                  src="images/63b6e31b4cbb39101fa8cea7_right-arrow_163b6e31b4cbb39101fa8cea7_right-arrow.png"
                                />
                              </div>
                              <div className="text-block-320">
                                What are the requirements to attend this course?
                              </div>
                            </div>
                            <div
                              className="accordion__content-wrap-4"
                              style={{ height: '0PX' }}
                            >
                              <div className="accordion__content_faq">
                                <div className="faq__block-4 last">
                                  <div className="div-block-40-faq">
                                    <div className="text-block-313">
                                      Access to a computer with an internet
                                      connection.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-col-3">
                <div className="about-box-2">
                  <div className="div-block-536">
                    <div className="vid-header-3 w-clearfix">
                      <img
                        src={imageUrl}
                        onError={handleImageError}
                        alt="course image"
                        className="video w-video w-embed c-image"
                      />

                      {/*  enrollment button will come here */}
                      {enrollmentStatusLoaded && (
                        <Courseenrollmentbutton
                          enrollmentStatus={enrollmentStatus}
                          courseID={courseID}
                          coursePrice={courseInfo.course_price}
                          courseName={courseInfo.display_name}
                        />
                      )}
                    </div>
                    <div className="div-block-537">
                      {courseInfo.course_price !== '' && (
                        <div className="div-block-535">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="/images/coin_1coin.png"
                          />
                          <div className="text-block-311">
                            {courseInfo.course_price}
                          </div>
                        </div>
                      )}

                      <div className="div-block-535">
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="/images/language_1language.png"
                        />
                        <div className="text-block-311">
                          {courseInfo.language}
                        </div>
                      </div>
                      <div className="div-block-535">
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="/images/certificate_1certificate.png"
                        />
                        <div className="text-block-311">Certificate</div>
                      </div>
                      {courseInfo.duration !== '' && (
                        <div className="div-block-535">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="/images/clock_3clock.png"
                          />
                          <div className="text-block-311">
                            {typeof courseInfo.duration === 'string'
                              ? courseInfo.duration
                              : secondsToHoursAndMinutes(courseInfo.duration)}
                          </div>
                        </div>
                      )}

                      {courseInfo.start_date !== '' && (
                        <div className="div-block-535">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="/images/date_1date.png"
                          />
                          <div className="text-block-311">
                            {formatReadableDate(courseInfo.start_date)}
                          </div>
                        </div>
                      )}

                      <div
                        className="div-block-535"
                        style={{ display: 'none' }}
                      >
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="images/63b5a1e8664d9617e9b5018f_level_icon_163b5a1e8664d9617e9b5018f_level_icon.png"
                        />
                        <div className="text-block-311">Beginner</div>
                      </div>

                      <div
                        className="div-block-535"
                        style={{ display: 'none' }}
                      >
                        <img
                          alt=""
                          className="skills-about-icon"
                          src="images/cc_1cc.png"
                        />
                        <div className="text-block-311">
                          Arabic, French, Portuguese, Italian, German, Russian,
                          English, Spanish, Korean, Japanese
                        </div>
                      </div>
                      <div
                        className="div-block-535 last"
                        style={{ display: 'none' }}
                      >
                        <div className="div-block-527">
                          <img
                            alt=""
                            className="skills-about-icon"
                            src="images/share_3share.png"
                          />
                          <div className="text-block-311">
                            Share this course
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetailViewContent;
