import React from 'react';
import chevronImg from "../../images/chevron-up.svg"
import $ from "jquery"
import { useDebounce } from '../../hooks/useDebounce';

const CopilotCloser = () => {

  const handleCopilotClose = () => {
    $(document).on('click', ".mentor-closer-block", function(){
      $('.body .full').removeClass("w-bar")
      $(".mentor-closer-block").addClass("hidden")
    })
  }

  useDebounce(handleCopilotClose, 200, [])
  return (
    <div className="mentor-closer-block hidden"><img src={chevronImg} loading="lazy" alt=""
                                                     className="mentor-closer-icon" /></div>
  );
};

export default CopilotCloser;