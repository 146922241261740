import React, { useEffect, useRef } from 'react';
import './Skillpointbox.css';
import NoItemView from '../NoItemView';
import Chart from 'chart.js/auto';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Skillpointbox = ({
  skillPointsGraphData,
  skillPointsGraphDataLoaded,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    console.log(
      '######################## skillPointsGraphDataLoaded ',
      skillPointsGraphDataLoaded
    );
    if (skillPointsGraphDataLoaded) {
      if (canvasRef.current) {
        canvasRef.current.destroy();
      }
      fillUpSkillPointsGraph(skillPointsGraphData);
    }
  }, [skillPointsGraphData, skillPointsGraphDataLoaded]);

  const fillUpSkillPointsGraph = (data) => {
    const results = data.results;
    if (results && results.body.length > 0) {
      const labelData = results.body.map((skill) => skill.skill.name);
      const datasetPoints = results.body.map((skill) => skill.total_points);
      const ctx = document.getElementById('skillPointsGraph');
      canvasRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labelData,
          datasets: [
            {
              label: 'Points',
              data: datasetPoints,
              backgroundColor: [
                'rgba(113, 160, 204, 0.8)',
                'rgba(81, 205, 108, 0.8)',
                'rgba(104, 81, 205, 0.8)',
                'rgba(81, 205, 198, 0.8)',
                'rgba(204, 195, 113, 0.8)',
              ],
              borderWidth: 2,
              borderColor: [
                '#71a0cc',
                '#51cd6c',
                '#6851cd',
                '#51cdc6',
                '#ccc371',
              ],
            },
          ],
        },
        options: {
          indexAxis: 'y',
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  };
  return (
    <div className="pr_g f inp home sp">
      <div className="div-block-14">
        <div className="skm">
          Skill Points
          <br />
        </div>
      </div>
      <div className="graphs-row">
        <div className="html-embed-2 skill-point dblock w-embed w-script">
          {!skillPointsGraphDataLoaded ? (
            <IBLSpinLoader size={40} />
          ) : skillPointsGraphData?.results?.body?.length > 0 ? (
            <canvas
              id="skillPointsGraph"
              style={{ width: '95%', height: '150px' }}
            ></canvas>
          ) : (
            <NoItemView
              imageUrl={'images/skills_icon_1skills_icon.png'}
              displayMessage={'No skill points yet.\n'}
              displayPadding={false}
            />
          )}
          {skillPointsGraphData?.results?.body?.length > 0 && (
            <div style={{ textAlign: 'center', marginTop: 10 }}>Points</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Skillpointbox;
