import React, { useState } from 'react';
import CourseIframeSideItem from './CourseIframeSideItem';

const CourseIframeSideMenu = ({
  courseData,
  setEdxIframe,
  changeIframeLoadingStatus,
  unitIdFromUrl,
  setUnitIdFromUrl,
  handleMenuCollapse,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleCollapseClick = () => {
    setIsExpanded(!isExpanded);
    handleMenuCollapse(!isExpanded);
  };

  return (
    <div className="course_inside_menu">
      <div className="left_m" style={{ width: isExpanded ? '255px' : 0 }}>
        <div className="left_m_w">
          <div className="faq-column-wrapper">
            <CourseIframeSideItem
              courseData={courseData}
              setEdxIframe={setEdxIframe}
              changeIframeLoadingStatus={changeIframeLoadingStatus}
              unitIdFromUrl={unitIdFromUrl}
              setUnitIdFromUrl={setUnitIdFromUrl}
            />
          </div>
        </div>
      </div>
      <div className="left_m-copy right">
        <a
          id="coll_btn"
          href="#"
          className="collapse_h w-inline-block"
          onClick={handleCollapseClick}
        >
          <img
            src="/images/65a9380c429528ae8933c7e2_5fad7f8a5d76925ee47292.png"
            loading="lazy"
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

export default CourseIframeSideMenu;
