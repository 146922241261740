import React, { useRef } from 'react';
import './Coursesbox.css';

import { EmptyBox, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import Courseslider from '../Courseslider';
//

const Coursesbox = ({userCourseData, userCourseDataLoaded}) => {
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow:
      userCourseData?.length >= 4 ? 4 : userCourseData?.length,
    slidesToScroll: 1,
    vertical: false,
    centerMode: false,
    centerPadding: '0',
    arrows: false,
  };


  const sliderRef = useRef(null);

  const handleNext = () => {
    // Use slickNext method to navigate to the next slide
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    // Use slickPrev method to navigate to the previous slide
    sliderRef.current?.slickPrev();
  };

  return (
    <div className="courses_res sl_c">
      <div className="flex home_path">
        {!userCourseDataLoaded ? (
          <IBLSpinLoader size={40} />
        ) : Array.isArray(userCourseData) &&
          userCourseData?.length > 0 ? (
          <Courseslider
            courses={userCourseData}
            handlePrev={handlePrev}
            handleNext={handleNext}
            sliderRef={sliderRef}
            sliderSettings={sliderSettings}
          />
        ) : (
          <EmptyBox />
        )}
      </div>
    </div>
  );
};

export default Coursesbox;
