import React, { useState } from 'react';
import './Searchbar.css';
import { useNavigate } from 'react-router-dom';
import searchImg from "../../images/magnifying-glass_1magnifying-glass.png"

const Searchbar = (props) => {
  const [searchedValue, setSearchedValue] = useState('');
  const navigate = useNavigate();

  const handleSearchBar = (e) => {
    e.preventDefault();
    if (searchedValue && searchedValue.length > 3) {
      navigate('discover/?q=' + encodeURI(searchedValue));
    }
    return;
  };
  return (
    <div className="form-block w-form">
      <form
        className="form"
        data-name="Email Form"
        data-wf-element-id="e2081ea6-597b-bc94-e36c-f71c33216c0b"
        data-wf-page-id="6596e21ac435cac3c66baaea"
        id="email-form"
        method="get"
        name="email-form"
        onSubmit={handleSearchBar}
      >
        <div className="div-block">
          <img
            alt=""
            className="image-2"
            loading="lazy"
            src={searchImg}
          />
        </div>
        <input
          className="text-field n_text w-input"
          data-name="Email 8"
          id="email-8"
          maxLength={256}
          name="email-8"
          placeholder="Search"
          required=""
          type="text"
          value={searchedValue}
          onChange={(e) => setSearchedValue(e?.target?.value)}
        />
      </form>
      <div className="w-form-done">
        <div>Thank you! Your submission has been received!</div>
      </div>
      <div className="w-form-fail">
        <div>Oops! Something went wrong while submitting the form.</div>
      </div>
    </div>
  );
};

export default Searchbar;
