import React, { useContext, useEffect } from 'react';
import {
  api,
  AppDataContext,
  ProfileBox,
  useIndex,
  useProfileCourses,
} from '@iblai/ibl-web-react-common';
import Focusskillbox from '../../../components/Focusskillbox/Focusskillbox';
import Credentialsbox from '../../../components/Credentialsbox/Credentialsbox';
import Statengagementbox from '../../../components/Statengagementbox/Statengagementbox';
import Skillleaderboardbox from '../../../components/Skillleaderboardbox/Skillleaderboardbox';
import Monthlyactivitybox from '../../../components/Monthlyactivitybox/Monthlyactivitybox';
import Skillpointbox from '../../../components/Skillpointbox/Skillpointbox';
import Coursesbox from '../../../components/Coursesbox/Coursesbox';
import { Link } from 'react-router-dom';
import './IndexViewContent.css';

const IndexViewContent = () => {
  const [
    userCredentialsData,
    userCredentialsDataLoaded,
    userMonthlyActivityData,
    userMonthlyActivityDataLoaded,
    userPathwaysData,
    userPathwaysDataLoaded,
    userSkillsData,
    userSkillsDataLoaded,
    engagementData,
    engagementDataLoaded,
    skillPointsGraphData,
    skillPointsGraphDataLoaded,
  ] = useIndex();
  const { appData, setAppData } = useContext(AppDataContext);
  const [coursesData, courseDataLoaded] = useProfileCourses(
    appData?.userData?.user_display_name
  );

  return (
    <span>
      <div className="div-block-326">
        <div className="div-block-317">
          <div className="welcome_div">
            <div className="p1">
              <div className="nav">
                <div className="nav-cont welcome">
                  <div className="nav-left">
                    <img
                      alt=""
                      className="image welcome"
                      loading="lazy"
                      src="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png"
                    />
                  </div>
                </div>
              </div>
              <div className="div-block-78">
                <div className="div-block-79">
                  <img
                    alt=""
                    className="image-47"
                    loading="lazy"
                    sizes="100vw"
                    src="images/Untitled-1.jpg"
                    srcSet="images/Untitled-1-p-500.jpeg 500w, images/Untitled-1.jpg 639w"
                  />
                  <div className="div-block-80">
                    <div className="text-block-60">Welcome to IBL Skills</div>
                    <div className="text-block-61">
                      Enjoy the first high-performance, affordable Skills
                      Learning on AWS Marketplace. It will empower you to build
                      —through selected content and a pedagogical method based
                      on machine learning — the right skills you need to boost
                      your career. 
                    </div>
                  </div>
                </div>
              </div>
              <div className="welcome_footer">
                <div className="dots">
                  <div className="div-block-82 active"></div>
                  <div className="div-block-82"></div>
                  <div className="div-block-82"></div>
                  <div className="div-block-82"></div>
                </div>
                <div className="div-block-81">
                  <div
                    className="g_s_btn first_btn"
                    data-w-id="e4983720-d1af-91c4-fab3-e55ae43faf72"
                  >
                    <div>Get Started</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p2">
              <div className="nav">
                <div className="nav-cont welcome">
                  <div className="nav-left">
                    <img
                      alt=""
                      className="image welcome"
                      loading="lazy"
                      src="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png"
                    />
                  </div>
                </div>
              </div>
              <div className="div-block-78">
                <div className="div-block-84">
                  <div className="text-block-62">
                    First, add or select the skills you want to develop
                  </div>
                  <div className="form-block welcome w-form">
                    <form
                      className="form welcome_f"
                      data-name="Email Form"
                      data-wf-element-id="b509103d-18d1-2175-335e-7d8db8e29760"
                      data-wf-page-id="6596e21ac435cac3c66baad7"
                      id="email-form"
                      method="get"
                      name="email-form"
                    >
                      <div className="div-block">
                        <img
                          alt=""
                          className="image-2"
                          loading="lazy"
                          src="images/magnifying-glass_1magnifying-glass.png"
                        />
                      </div>
                      <input
                        className="text-field w-input"
                        data-name="Email 6"
                        id="email-6"
                        maxLength={256}
                        name="email-6"
                        placeholder=""
                        required=""
                        type="email"
                      />
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <div className="tx_s">18 Selected Skills</div>
                  <div className="div-block-83">
                    <div className="div-block-17">
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Event Management
                        </div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">Leadership</div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">Marketing</div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Software Development
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Communication
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Software Project Management
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Local Government
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Social Media Marketing
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Social Commentary
                        </div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">Feminism</div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">
                          Socieconomics
                        </div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">
                          Business Comminications
                        </div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">
                          Communication Skills
                        </div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">
                          Computer Skills
                        </div>
                      </div>
                      <div className="div-block-16 welcome">
                        <div className="text-block-12 welcome">Editing</div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Graphic Design
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Information Architecture
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Research
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Socieconomic development
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Software Testing
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Time Management
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Leadership Dallas
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Instructional Design
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Qualitative Research
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Socioeconomic Impact of Female Education
                        </div>
                      </div>
                      <div className="div-block-16 welcome selected">
                        <img
                          alt=""
                          className="image-48"
                          loading="lazy"
                          src="images/checked_1checked.png"
                        />
                        <div className="text-block-12 welcome selected">
                          Change Management
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add_skills">
                    <img
                      alt=""
                      className="image-52"
                      loading="lazy"
                      src="images/plus-1_1plus-1.png"
                    />
                  </div>
                </div>
              </div>
              <div className="welcome_footer">
                <div className="dots">
                  <div className="div-block-82"></div>
                  <div className="div-block-82 active"></div>
                  <div className="div-block-82"></div>
                  <div className="div-block-82"></div>
                </div>
                <div className="div-block-81">
                  <div
                    className="g_s_btn-before"
                    data-w-id="9aa50997-bb9c-24ef-8f07-07e00266d9d3"
                  >
                    <div>Previous Step</div>
                  </div>
                  <div className="g_s_btn">
                    <div>Next Step</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p3">
              <div className="nav">
                <div className="nav-cont welcome">
                  <div className="nav-left">
                    <img
                      alt=""
                      className="image welcome"
                      loading="lazy"
                      src="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png"
                    />
                  </div>
                </div>
              </div>
              <div className="div-block-78">
                <div className="div-block-84 level_rate">
                  <div className="text-block-62">Now, rate your level</div>
                  <div className="div-block-85">
                    <div className="text-block-63">Event Management</div>
                    <div className="div-block-86">
                      <img
                        alt=""
                        className="image-49"
                        loading="lazy"
                        src="images/invisible_1invisible.png"
                      />
                      <div>Visible only to you</div>
                    </div>
                    <div className="div-block-88">
                      <div className="div-block-87">
                        <div className="step_n">1</div>
                        <div className="step_n">2</div>
                        <div className="step_n">3</div>
                        <div className="step_n">4</div>
                        <div className="step_n">5</div>
                        <div className="step_n">6</div>
                        <div className="step_n">7</div>
                        <div className="step_n">8</div>
                        <div className="step_n">9</div>
                        <div className="step_n">10</div>
                      </div>
                      <div className="fs-rangeslider_form w-form">
                        <form
                          data-name="Email Form"
                          data-wf-element-id="47e4e54b-213d-cbb6-63a5-d1d9a2e318e5"
                          data-wf-page-id="6596e21ac435cac3c66baad7"
                          id="email-form"
                          method="get"
                          name="email-form"
                        >
                          <div
                            className="fs-rangeslider_wrapper"
                            fs-rangeslider-element="wrapper"
                            fs-rangeslider-formatdisplay="true"
                            fs-rangeslider-max={90}
                            fs-rangeslider-min={0}
                            fs-rangeslider-step={10}
                          >
                            <div
                              className="fs-rangeslider_track"
                              fs-rangeslider-element="track"
                            >
                              <div
                                className="fs-rangeslider_handle is-pink"
                                fs-rangeslider-element="handle"
                              >
                                <div className="fs-rangeslider_handle-value"></div>
                              </div>
                              <div
                                className="fs-rangeslider_fill"
                                fs-rangeslider-element="fill"
                              ></div>
                            </div>
                            <input
                              className="fs-rangeslider_input w-input"
                              data-name="From 3"
                              fs-cmsfilter-field="price"
                              fs-cmsfilter-range="from"
                              id="From-3"
                              maxLength={256}
                              name="From-3"
                              placeholder="From"
                              type="text"
                            />
                            <div className="fs-range_values hidden"></div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>
                            Thank you! Your submission has been received!
                          </div>
                        </div>
                        <div className="w-form-fail">
                          <div>
                            Oops! Something went wrong while submitting the
                            form.
                          </div>
                        </div>
                      </div>
                      <div className="div-block-89">
                        <div className="text-block-64">Beginner</div>
                        <div className="text-block-64 mgl">Expert</div>
                      </div>
                      <div className="div-block-90">
                        <img
                          alt=""
                          className="image-50"
                          loading="lazy"
                          src="images/Screenshot-2022-06-09-at-3.57.09-PM-copy.png"
                        />
                        <div className="text-block-65">
                          Can complete narrow and limited tasks requiring basic
                          skills with direct supervision or guidance.
                        </div>
                        <div className="div-block-91"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="welcome_footer">
                <div className="dots">
                  <div className="div-block-82"></div>
                  <div className="div-block-82"></div>
                  <div className="div-block-82 active"></div>
                  <div className="div-block-82"></div>
                </div>
                <div className="div-block-81">
                  <div
                    className="g_s_btn-before"
                    data-w-id="9928e986-ed6f-637d-c317-082c6b14b8b9"
                  >
                    <div>Previous Step</div>
                  </div>
                  <div
                    className="g_s_btn-before skip"
                    data-w-id="3eae9302-eb11-698f-76b4-89e59390c401"
                  >
                    <div>Skip Step</div>
                  </div>
                  <div
                    className="g_s_btn r_n"
                    data-w-id="9928e986-ed6f-637d-c317-082c6b14b8bc"
                  >
                    <div>Next Step</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p4">
              <div className="nav">
                <div className="nav-cont welcome">
                  <div className="nav-left">
                    <img
                      alt=""
                      className="image welcome"
                      loading="lazy"
                      src="images/629f2930d89dd16507eaaf49_logo-cla-1-copy_1629f2930d89dd16507eaaf49_logo-cla-1-copy.png"
                    />
                  </div>
                </div>
              </div>
              <div className="div-block-78">
                <div className="div-block-92">
                  <div className="text-block-66">
                    Start Boosting Your Skills!
                  </div>
                  <div className="text-block-67">
                    Check our content selection or manually add more learning
                    items
                  </div>
                  <img
                    alt=""
                    className="image-51"
                    loading="lazy"
                    src="images/4721728.jpg"
                  />
                </div>
              </div>
              <div className="welcome_footer">
                <div className="dots">
                  <div className="div-block-82"></div>
                  <div className="div-block-82"></div>
                  <div className="div-block-82"></div>
                  <div className="div-block-82 active"></div>
                </div>
                <div className="div-block-81">
                  <div
                    className="g_s_btn-before"
                    data-w-id="b5575d53-82a4-eb35-2e00-06afccc31678"
                  >
                    <div>Previous Step</div>
                  </div>
                  <div className="g_s_btn r_n">
                    <div>Go</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panels">
            <div className="panels_cont w_chat">
              <div className="panel-home panel">
                <div className="home_left">
                  <ProfileBox />
                  <Focusskillbox
                    userSkillsData={userSkillsData}
                    userSkillsDataLoaded={userSkillsDataLoaded}
                  ></Focusskillbox>
                  <Credentialsbox
                    credentialsData={userCredentialsData}
                    userCredentialsDataLoaded={userCredentialsDataLoaded}
                    appData={appData}
                  ></Credentialsbox>
                  {/*<Searchcontentbox></Searchcontentbox>*/}
                  <div className="prof_box grey hidden">
                    <div className="div-block-376">
                      <img
                        alt=""
                        className="image-127"
                        loading="lazy"
                        src="images/plus-2_1plus-2.png"
                      />
                    </div>
                    <div className="text-block sec_title ex_co amc">
                      Add My Content
                    </div>
                  </div>
                  <div className="prof_box hidden">
                    <div className="text-block sec_title">Monthly Activity</div>
                    <div>
                      <div className="div-block-20">
                        <div className="div-block-21">
                          <img
                            alt=""
                            className="image-17"
                            loading="lazy"
                            src="images/skills_icon_1skills_icon.png"
                          />
                        </div>
                        <div className="text-block-14">Skills Achieved</div>
                        <div className="text-block-15">(25)</div>
                      </div>
                      <div className="div-block-20">
                        <div className="div-block-21">
                          <img
                            alt=""
                            className="image-17"
                            loading="lazy"
                            sizes="100vw"
                            src="images/check_2.png"
                            srcSet="images/check_2-p-500.png 500w, images/check_2.png 512w"
                          />
                        </div>
                        <div className="text-block-14">Course Completions</div>
                        <div className="text-block-15">(12)</div>
                      </div>
                      <div className="div-block-20">
                        <div className="div-block-21">
                          <img
                            alt=""
                            className="image-17"
                            loading="lazy"
                            sizes="100vw"
                            src="images/trophy.png"
                            srcSet="images/trophy-p-500.png 500w, images/trophy.png 512w"
                          />
                        </div>
                        <div className="text-block-14">Skill Coins Earned</div>
                        <div className="text-block-15">(105)</div>
                      </div>
                    </div>
                    <div className="div-block-18">
                      <a
                        className="link-block-4 w-inline-block"
                        href="https://ibl-skills.webflow.io/profile#activity"
                      >
                        <div className="text-block-13">All Activity</div>
                        <img
                          alt=""
                          className="image-15 rc"
                          loading="lazy"
                          src="images/chevron_1chevron.png"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="home_right">
                  <Statengagementbox
                    engagementData={engagementData}
                    engagementDataLoaded={engagementDataLoaded}
                  ></Statengagementbox>
                  <div className="div-block-33">
                    <div className="div-block-29 w100">
                      <div className="div-block-12 hidden">
                        <div className="text-block-21">Skills</div>
                        <div className="text-block-11">Manage Skills</div>
                      </div>
                      <Skillleaderboardbox
                        useLoader={true}
                        isLoading={!userSkillsDataLoaded}
                      />
                    </div>
                  </div>
                  <div className="div-block-48 home db">
                    <Monthlyactivitybox
                      userMonthlyActivityData={userMonthlyActivityData}
                      userMonthlyActivityDataLoaded={
                        userMonthlyActivityDataLoaded
                      }
                    ></Monthlyactivitybox>
                    <Skillpointbox
                      skillPointsGraphData={skillPointsGraphData}
                      skillPointsGraphDataLoaded={skillPointsGraphDataLoaded}
                    ></Skillpointbox>
                  </div>

                  {/*<div className="text-block-10 mg_b_20">Pathways</div>*/}
                  {/*<Pathwaybox userPathwaysData ={userPathwaysData} userPathwaysDataLoaded={userPathwaysDataLoaded}></Pathwaybox>*/}
                  {/*    <div className="text-block-13 more">*/}
                  {/*<a className="link-6 more" href="profile/pathways.html">More</a>*/}
                  {/*</div>*/}
                  <div className="text-block-10 mg_b_20">Courses</div>
                  <Coursesbox
                    userCourseData={coursesData}
                    userCourseDataLoaded={courseDataLoaded}
                  ></Coursesbox>
                  <div className="text-block-13 more"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="social_div">
            <div className="social_cont">
              <div className="social_box followers" data-name="followers">
                <div className="div-block-147">
                  <div>Followers</div>
                  <img
                    alt=""
                    className="image-72"
                    loading="lazy"
                    src="images/close_2close.png"
                  />
                </div>
                <div className="div-block-148">
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/t6.jpg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Sara Joyner</div>
                        <div className="text-block-94">
                          Manual Testing Engineer
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Remove</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec84145530b12f5abd1e_5f5625e328218db2b3242892_t4-1.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Eathan Adam</div>
                        <div className="text-block-94">Mining Engineer</div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Remove</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec6101ef995f75468275_5f5625e328218dc96c242881_3.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Maira Paul</div>
                        <div className="text-block-94">
                          Technical Support Representative
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Remove</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec104e702cd2e72aabf0_5f5625e328218de5e4242883_t1-1.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Nimra Jefferson</div>
                        <div className="text-block-94">
                          Software Quality Assurance
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Remove</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec3ab27958e500de411b_5f5625e328218d67b6242873_t2-1.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Kealan Walters</div>
                        <div className="text-block-94">
                          Computer Science Professor
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Remove</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        sizes="100vw"
                        src="images/5eb952f0f5ff0d39622e72e7_c1.jpg"
                        srcSet="images/5eb952f0f5ff0d39622e72e7_c1-p-500.jpeg 500w, images/5eb952f0f5ff0d39622e72e7_c1.jpg 700w"
                      />
                      <div className="div-block-150">
                        <div className="bname">Isobella Simmonds</div>
                        <div className="text-block-94">Product Manager</div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Remove</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="social_box following" data-name="followers">
                <div className="div-block-147">
                  <div>Following</div>
                  <img
                    alt=""
                    className="image-72 following"
                    loading="lazy"
                    src="images/close_2close.png"
                  />
                </div>
                <div className="div-block-148">
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        sizes="100vw"
                        src="images/5eb952f0f5ff0d39622e72e7_c1.jpg"
                        srcSet="images/5eb952f0f5ff0d39622e72e7_c1-p-500.jpeg 500w, images/5eb952f0f5ff0d39622e72e7_c1.jpg 700w"
                      />
                      <div className="div-block-150">
                        <div className="bname">Isobella Simmonds</div>
                        <div className="text-block-94">Product Manager</div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Following</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec104e702cd2e72aabf0_5f5625e328218de5e4242883_t1-1.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Nimra Jefferson</div>
                        <div className="text-block-94">
                          Software Quality Assurance
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Following</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec84145530b12f5abd1e_5f5625e328218db2b3242892_t4-1.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Eathan Adam</div>
                        <div className="text-block-94">Mining Engineer</div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Following</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec6101ef995f75468275_5f5625e328218dc96c242881_3.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Maira Paul</div>
                        <div className="text-block-94">
                          Technical Support Representative
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Following</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/t6.jpg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Sara Joyner</div>
                        <div className="text-block-94">
                          Manual Testing Engineer
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Following</div>
                    </div>
                  </div>
                  <div className="div-block-149">
                    <a
                      className="link-block-6 w-inline-block"
                      href="https://ibl-skills.webflow.io/public-profile"
                    >
                      <img
                        alt=""
                        className="image-73"
                        loading="lazy"
                        src="images/60f6ec3ab27958e500de411b_5f5625e328218d67b6242873_t2-1.jpeg"
                      />
                      <div className="div-block-150">
                        <div className="bname">Kealan Walters</div>
                        <div className="text-block-94">
                          Computer Science Professor
                        </div>
                      </div>
                    </a>
                    <div className="div-block-151">
                      <div className="text-block-95">Following</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};
export default IndexViewContent;
