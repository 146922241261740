import Contentfilterbox from '../../components/Contentfilterbox/Contentfilterbox';
import React, { useState } from 'react';

import useDiscover from '../../hooks/useDiscover';
import Coursesfilterbox from '../../components/Coursesfilterbox';
import ReactPaginate from 'react-paginate';
import { getDiscoverItemsAPI } from '../../hooks/utils/discover';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const BrowseContentView = () => {
  const [discoverItems, discoverLoaded, setDiscoverItems, setDiscoverLoaded] =
    useDiscover();
  const [currentPage, setCurrentPage] = useState(1);
  const [discoverItemsTotal, setDiscoverItemsTotal] = useState(0);
  const [selectedTerms, setSelectedTerms] = useState([]);

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverItemsTotal(data.num_pages);
  };
  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    getDiscoverItemsAPI(discoverCallback, (selected + 1), selectedTerms, '');
  };

  const facetHandler = () => {
    getDiscoverItemsAPI(discoverCallback, currentPage, selectedTerms, '');
  };

  return (
    <span>
      <div className="panels">
        <div className="panels_cont">
          <div className="panel-browse panel">
            <div className="browse">
              <div className="browse_panel active" data-name="browse">
                <div className="browse_cont">
                  <div className="browse_left">
                    <div className="prof_box browse">
                      <div no-mapping="" className="text-block sec_title ex_co">
                        Explore Content
                      </div>
                      <div no-mapping="" className="text-block sec_title fb">
                        Filter By
                      </div>

                      {discoverLoaded && (
                        <Contentfilterbox
                          discoverItems={discoverItems}
                          selectedTerms={selectedTerms}
                          setSelectedTerms={setSelectedTerms}
                          facetHandler={facetHandler}
                        ></Contentfilterbox>
                      )}
                    </div>

                    <div className="prof_box grey s hidden">
                      <div className="div-block-376">
                        <img
                          alt=""
                          className="image-127"
                          loading="lazy"
                          src="images/plus-2_1plus-2.png"
                        />
                      </div>
                      <div className="text-block sec_title ex_co amc">
                        Add My Content
                      </div>
                    </div>
                  </div>
                  <div className="br_panel" data-name="top">
                    <div className="text-block-308">
                      Featured Learning Content
                    </div>
                    {!discoverLoaded ? (
                      <IBLSpinLoader size={40} containerHeight={'300px'} />
                    ) : (
                      <>
                        <div className="div-block-430 sl pathway_grid">
                          <Coursesfilterbox
                            discoverItems={discoverItems}
                            discoverLoaded={discoverLoaded}
                          />
                        </div>
                        <ReactPaginate
                          onPageChange={paginate}
                          pageCount={discoverItems.num_pages}
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          containerClassName={'pagination'}
                          pageLinkClassName={'page-number'}
                          previousLinkClassName={'page-number'}
                          nextLinkClassName={'page-number'}
                          activeLinkClassName={'active'}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default BrowseContentView;
