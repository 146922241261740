import React from 'react';
import './Skillleaderboardbox.css';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Skillleaderboardbox = ({ useLoader, isLoading }) => {
  return (
    <div className="div-block-13 l-h h">
      <div className="div-block-14">
        <div className="skm">
          Skill Leaderboard
          <br />
        </div>
      </div>
      <div className="div-block-238">
        {!!useLoader && !!isLoading ? (
          <IBLSpinLoader size={40} containerHeight={'150px'} />
        ) : (
          <div className="html-embed-2 _w-w w-embed">
            <style
              dangerouslySetInnerHTML={{
                __html: `
.af-view .skills-c .sv_img{
  height:158px;
  width:100%;
}
.af-view .skills-c .axisLabel__ac6OU{
margin-left:auto;
margin-right:auto;
}
.af-view .skills-c {
 overflow:auto;
}
.af-view .skills-c .a {
min-width:570px;
color: #6d717f !important;
background: transparent;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: end;
-ms-flex-align: end;
align-items: flex-end;
font-size: 12px;
font-size: var(--ps-type-font-size-100);
font-weight: 500;
font-weight: var(--ps-type-font-weight-500);
padding: 16px 32px;
padding: var(--ps-layout-spacing-medium) 32px;
margin-bottom: 16px;
margin-bottom: var(--ps-layout-spacing-medium);
}
.af-view .skills-c .titleContainer__Fwuxu {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
font-size: 16px;
font-size: var(--ps-type-font-size-base);
text-align: left;
padding-bottom: 24px;
padding-bottom: var(--ps-layout-spacing-large);
width: 100%;
}
.af-view .skills-c .b {
padding-bottom: 59px;
position: relative;
margin-left: -5%;
width:auto%;
}
.af-view .skills-c .c {
opacity: 65%;
position: absolute;
right: 26px;
top: -8px;
}
.af-view .skills-c .d {
background: #FFFFFF;
opacity: 30%;
height: 1px;
margin-left: auto;
margin-right: 13px;
max-width: 7px;
}
.af-view .skills-c .f {
margin: 0 16px 0 0;
border-right-color: #cecece;
border-right-width: 1px;
border-right-style: solid;
padding-left: 15px;
line-height: 147.5px;
max-height: 151.5px;
}
.af-view .skills-c .f > p {
opacity: 95%;
-webkit-transform: rotate(-90deg);
transform: rotate(-90deg);
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
text-align: center;
}
.af-view .skills-c .g {
opacity: 65%;
position: absolute;
right: 26px;
bottom: 59px;
}
.af-view .skills-c .i {
width:85%
}
.af-view .skills-c .h {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-box-pack: start;
-ms-flex-pack: start;
justify-content: flex-start;
}
.af-view .skills-c .j {
height: 100%;
position: relative;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
width: 100%;
}
.af-view .skills-c .k {
width: 100%;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-ms-flex-direction: row;
flex-direction: row;
}
.af-view .skills-c .m:first-of-type {
margin-left: 0px;
}
.af-view .skills-c .l dt {
background-color: #d52ace ;
}
.af-view .skills-c .n dt {
height: 3px;
}
.af-view .skills-c .m dt {
position: absolute;
top: 0px;
left: 0px;
width: 100%;
}
.af-view .skills-c .m {
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
-ms-flex-preferred-size: 0;
flex-basis: 0;
font-size: 14px;
font-weight: 400;
text-align: center;
padding-top: 12px;
line-height: 18px;
margin-left: 1px;
overflow: hidden;
position: relative;
color: rgba(255, 255, 255, 0.7);
color: var(--ps-colors-text-icon-low-on-dark);
padding-bottom: 0;
margin-bottom:0;
}
.af-view .skills-c .s {
width: 100%;
position: relative;
height: 175px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: end;
-ms-flex-align: end;
align-items: flex-end;
}
.af-view .skills-c .t {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
height: 237px;
width: 100%;
position: absolute;
bottom: -36px;
left: 0;
}
.af-view .skills-c .u.w.v {
background-color: #d52ace ;
}
.af-view .skills-c .v {
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
opacity: 20%;
width: 100%;
margin-right: 1px;
height: 0;
-webkit-transition: opacity 15000ms ease-in-out;
transition: opacity 15000ms ease-in-out;
}
@keyframes fadeInAnimation {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}
.af-view .skills-c .v{
          animation: fadeInAnimation ease-in-out 500ms;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
}
.af-view .skills-c .percentileScore__eqaA3 {
position: absolute;
bottom: 0;
left: 0;
-webkit-transform: translate(-50%, -12px);
transform: translate(-50%, -12px);
width: 84px;
text-align: center;
font-size: 14px;
line-height: 14px;
background: transparent;
line-height: 14px;
color: rgba(255, 255, 255, 0.95);
color: var(--ps-colors-text-icon-high-on-dark);
}
.af-view .skills-c .scoreBase__Z1ipd {
position: absolute;
bottom: 0;
left: 0;
min-width: 2px;
min-height: 7px;
max-width: 410px;
max-height: 124px;
border-top: 1px dashed rgba(255, 255, 255, 0.7);
}
.af-view .skills-c .score__IvWkK {
color: #6d717f;
margin: 0px;
position: absolute;
left: -25px;
background: #fff;
top:-11px
/* bottom:0; */
}
@keyframes moveBottom {
    0% {
        bottom: 0;
    }
    100% {
        bottom: 27px;
    }
}
/* .score__IvWkK{
          animation: moveBottom ease-in-out 800ms;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
} */
@keyframes moveWidth {
    0% {
        width: 0;
    }
    10% {
        width: 0;
    }
    100% {
        width: 8.3%;
    }
}
@keyframes moveHeight {
    0% {
        height: 0;
    }
    85% {
        height: 40px;
    }
    100%{
          height: 40px;
    }
}
.skills-c .scoreBase__Z1ipd{
          animation: moveWidth ease-in-out 900ms, moveHeight ease-in-out 900ms;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
}
/* .scoreBase__Z1ipd{
          animation: moveHeight moveWidth ease-in-out 800ms;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
} */
.af-view .skills-c .gravatarUrl__AzQ29 {
background: #fff;
border-radius: 100px;
position: absolute;
width: 15px;
height: 15px;
top: -8px;
right: -8px;
border-width: 3px;
border-style: solid;
-webkit-box-sizing: border-box;
box-sizing: border-box;
z-index: 2;
-webkit-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 20%);
box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 20%);
}
.af-view .skills-c .shape__EatFN {
-webkit-box-sizing: border-box;
box-sizing: border-box;
opacity: 0.2;
width: 100%;
height: 190px;
position: absolute;
left: 0;
bottom: 0;
overflow: hidden;
}
.af-view .skills-c .curve__fdcsg {
width: 100%;
position: relative;
background: transparent;
}
.af-view .skills-c .line__EoBr7 {
fill: none;
}
.af-view .skills-c .xAxis__LMHy1 {
width: 100%;
height: 100%;
position: relative;
top: -1px;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
border-top: 1px solid #cecece;
}
.af-view .skills-c .xAxis__LMHy1::before {
left: -20px;
width: 20px;
}
.af-view .skills-c .xAxis__LMHy1::before, .af-view .xAxis__LMHy1::after {
content: ' ';
height: 1px;
background-color: rgba(255, 255, 255, 0.3);
position: absolute;
top: -1px;
}
.af-view .skills-c .levels__BRSQn {
width: 100%;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-ms-flex-direction: row;
flex-direction: row;
}
.af-view .level__QZ3Q9 {
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
-ms-flex-preferred-size: 0;
flex-basis: 0;
font-size: 14px;
font-weight: 400;
text-align: center;
line-height: 18px;
position: relative;
}
.af-view .active__DIwr9 {
color: white;
}
.af-view .range__DIpW_ {
font-size: 12px;
font-weight: 400;
color: rgba(255, 255, 255, 0.7);
color: var(--ps-colors-text-icon-low-on-dark);
margin: 0;
}
.af-view .graphFooter__q7_RH {
-webkit-box-flex: 1;
-ms-flex: 1 auto;
flex: 1 auto;
padding: 16px 0;
padding: 5px 0;
width: 100%;
}
.af-view .skills-c .k dl{
color: #6d717f;
}
.af-view /* .w {
height: 100%;
} */
 `,
              }}
            />
            <div className="skills-c">
              <div className="a">
                <div aria-hidden="true" className="b">
                  <div className="c">100</div>
                  <div className="d"></div>
                  <div className="f">
                    <p>Skill Points</p>
                  </div>
                  <div className="g">0</div>
                </div>
                <div aria-hidden="true" className="h i">
                  <div aria-hidden="true" className="j">
                    <div className="k">
                      <dl className="l m n">
                        <dt></dt>
                        Beginner
                      </dl>
                      <dl className="o m">
                        <dt></dt>
                        Novice
                      </dl>
                      <dl className="p m">
                        <dt></dt>
                        Intermediate
                      </dl>
                      <dl className="q m">
                        <dt></dt>
                        Advanced
                      </dl>
                      <dl className="r m">
                        <dt></dt>
                        Expert
                      </dl>
                    </div>
                  </div>
                  <div className="s">
                    <div className="t">
                      <div className="u v w"></div>
                      <div className="x v"></div>
                      <div className="y v"></div>
                      <div className="z v"></div>
                      <div className="expert__EK4nC v"></div>
                    </div>
                    <p
                      className="percentileScore__eqaA3"
                      style={{ left: '41.4px', bottom: '43.935px' }}
                    ></p>
                    <div className="scoreBase__Z1ipd">
                      <p className="score__IvWkK">0</p>
                      <div>
                        <span
                          className="gravatarUrl__AzQ29"
                          style={{ borderColor: '#d52ace' }}
                        ></span>
                      </div>
                      <div className="shape__EatFN">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <img
                      alt=""
                      className="sv_img"
                      src="https://uploads-ssl.webflow.com/62b1d984a1c1ca31804d1115/6310e18af762364ab27fd938_62f689efea0370abfc62c141_ex4.png"
                    />
                  </div>
                  <div className="xAxis__LMHy1">
                    <div className="levels__BRSQn">
                      <dl className="level__QZ3Q9">
                        <dd className="range__DIpW_ active__DIwr9">
                          basic knowledge
                        </dd>
                      </dl>
                      <dl className="level__QZ3Q9">
                        <dd className="range__DIpW_">limited experience</dd>
                      </dl>
                      <dl className="level__QZ3Q9">
                        <dd className="range__DIpW_">practical application</dd>
                      </dl>
                      <dl className="level__QZ3Q9">
                        <dd className="range__DIpW_">applied theory</dd>
                      </dl>
                      <dl className="level__QZ3Q9">
                        <dd className="range__DIpW_">recognized authority</dd>
                      </dl>
                    </div>
                    <div className="axisLabel__ac6OU">
                      Global Learner Percentile
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Skillleaderboardbox;
