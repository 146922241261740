import React, { useEffect, useRef } from 'react';
import { getMonthLabels, splitIntoWeeksAndMonths } from '../hooks/utils';

const EngagementChart = ({ data, tooltipRef }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!data) return;

    const weeksAndMonths = splitIntoWeeksAndMonths(Object.entries(data));
    const svgNS = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(svgNS, 'svg');
    svg.setAttribute('class', 'js-calendar-graph-svg');
    svg.setAttribute('width', '805');
    svg.setAttribute('height', '120');
    svg.setAttribute('viewBox', '0 0 721 110');

    const g = document.createElementNS(svgNS, 'g');
    g.setAttribute('transform', 'translate(20, 20)');

    for (let i = 0; i < weeksAndMonths.length; i++) {
      const weekOrMonth = weeksAndMonths[i];
      const entries = weekOrMonth.entries;

      const gc = document.createElementNS(svgNS, 'g');
      const translateX = i * 12;

      gc.setAttribute('transform', `translate(${translateX}, 0)`);

      // Create and append the <rect> elements for each entry
      for (let j = 0; j < entries.length; j++) {
        const entry = entries[j];
        const colors = ['#e2f1fb', '#aad3f0', '#93c4e7', '#81bbdf', '#629abc'];
        const dayOfWeek = new Date(entry[0]).getDay();
        const rect = document.createElementNS(svgNS, 'rect');
        const seconds = entry[1];
        const minutes = Math.round(seconds / 60);
        let selectedColor;
        if (minutes == 0) {
          selectedColor = '#e2f1fb';
        } else if (minutes < 10) {
          selectedColor = '#aad3f0';
        } else if (minutes < 30) {
          selectedColor = '#93c4e7';
        } else if (minutes < 60) {
          selectedColor = '#81bbdf';
        } else if (minutes >= 60) {
          selectedColor = '#629abc';
        }
        rect.setAttribute('class', 'day');
        rect.setAttribute('width', '10');
        rect.setAttribute('height', '10');
        rect.setAttribute('y', dayOfWeek * 12);
        rect.setAttribute('fill', selectedColor);
        rect.setAttribute('data-count', minutes);
        rect.setAttribute('data-dayOfWeek', dayOfWeek);
        rect.setAttribute('data-date', entry[0]);
        rect.setAttribute('rx', '2');
        rect.setAttribute('ry', '2');
        gc.appendChild(rect);
      }
      g.appendChild(gc);
    }

    const monthLabels = getMonthLabels();
    const weekdayLabels = ['Mon', 'Wed', 'Fri'];

    for (let i = 0; i < 13; i++) {
      const monthText = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
      );
      monthText.setAttribute('x', i * 52);
      monthText.setAttribute('y', -5);
      monthText.setAttribute('class', 'month');
      monthText.textContent = monthLabels[i];
      g.appendChild(monthText);
    }

    for (let i = 0; i < 3; i++) {
      const weekdayText = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
      );
      weekdayText.setAttribute('text-anchor', 'middle');
      weekdayText.setAttribute('class', 'wday');
      weekdayText.setAttribute('dx', -15);
      weekdayText.setAttribute('dy', i * 26 + 20);
      weekdayText.textContent = weekdayLabels[i];
      g.appendChild(weekdayText);
    }

    svg.appendChild(g);

    if (svgRef.current) {
      while (svgRef.current.firstChild) {
        svgRef.current.removeChild(svgRef.current.firstChild);
      }
      svgRef.current.appendChild(svg);
    }

    const rects = svgRef.current.querySelectorAll('rect.day');

    rects.forEach((rect) => {
      rect.addEventListener('mouseover', handleMouseOver);
      rect.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      rects.forEach((rect) => {
        rect.removeEventListener('mouseover', handleMouseOver);
        rect.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [data]);

  function handleMouseOver(event) {
    const svg = event.target.closest('svg');
    const svgRect = svg.getBoundingClientRect();
    const count = event.target.getAttribute('data-count');
    const date = event.target.getAttribute('data-date');
    const rectRect = event.target.getBoundingClientRect();
    const rectTop = rectRect.top - svgRect.top;
    const rectLeft = rectRect.left - svgRect.left;

    // Set innerHTML for tooltip
    tooltipRef.current.innerHTML =
      `<text x="0" y="15" class="svg-tip-date">${date}</text>` +
      `<text x="0" y="30" class="svg-tip-count"> | Time Spent: ${count} ${count === '1' ? 'minute' : 'minutes'}</text>`;
    tooltipRef.current.style.display = 'block';

    // Positioning tooltip without considering window bounds
    // Center tooltip above or below the rect element
    const leftPosition =
      rectLeft + rectRect.width / 3 - tooltipRef.current.offsetWidth / 3;
    const topPosition = rectTop + rectRect.height + 10; // 10px below the rect

    // Apply positions directly without adjustments for window edges
    tooltipRef.current.style.left = `${leftPosition}px`;
    tooltipRef.current.style.top = `${topPosition}px`;
  }

  const handleMouseLeave = () => {
    tooltipRef.current.style.display = 'none';
  };

  return <div ref={svgRef} className="div-block-33 sp"></div>;
};

export default EngagementChart;
