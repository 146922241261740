import Searchbar from '../Searchbar/Searchbar';
import React, { useContext, useEffect, useState } from 'react';
import './Header.css';
import { Link, NavLink } from 'react-router-dom';
import Headerprofilemenue from '../Headerprofilemenue';
import {
  getAnalyticsURL,
  getLoginURL,
  getTenant,
  isCustomMenusEnabled, isCustomSubMenusEnabled,
  isStaticPagesEnabled,
  useCustomMenus,
} from '../../hooks/helpers';
import { AppDataContext, GLOBAL_COLORS, IBLUserProfile, isJSON } from '@iblai/ibl-web-react-common';
import { HiOutlineBell, HiOutlineUsers } from 'react-icons/hi';
import $ from 'jquery';
import { useDebounce } from '../../hooks/useDebounce';

const STATIC_PAGES_ENABLED = isStaticPagesEnabled();
const CUSTOM_MENUS_ENABLED = isCustomMenusEnabled()
const CUSTOM_SUBMENUS_ENABLED = isCustomSubMenusEnabled()


const analyticsURL = getAnalyticsURL();
const loginURL = getLoginURL();
const Header = (props) => {
  const { appData, setAppData } = useContext(AppDataContext);
  const [logoURL, setLogoURL] = useState('');
  const [customMenus, setCustomMenus] = useState([]);
  const [customSubMenus, setCustomSubMenus] = useState([]);
  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');

    if (favicon) {
      favicon.href = process.env.REACT_APP_FAVICON_URL;
    } else {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = process.env.REACT_APP_IBL_DEFAULT_FAVICON_URL;
      document.head.appendChild(link);
    }
  }, []);

  const handleCustomMenu = () => {
    const subMenusList = []
    const originURL = new URL(window.location.href).origin

    if(CUSTOM_SUBMENUS_ENABLED){
      const subMenuInString = process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_HTML_SUBMENUS;
      const firstLevelSubMenus = String(subMenuInString).split('|');
      if (firstLevelSubMenus.length > 0) {
        firstLevelSubMenus.forEach((menus) => {
          const menu = String(menus).split(':');
          subMenusList.push({
            label: menu[0],
            src: `${originURL}/${process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_IFRAME_SUBMENU_HTML_SUB_FOLDER_PATH}/${menu[1]}.html`,
          });
        });
      }
    }
    const menuInString = process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_MENUS;
    const firstLevelMenus = String(menuInString).split('|');
    if (firstLevelMenus.length > 0) {
      const menusList = [];
      firstLevelMenus.forEach((menus) => {
        const menu = String(menus).split(':');
        const submenu =  subMenusList.find((el)=>el.label=== menu[0])
        menusList.push({
          label: menu[0],
          link: menu[1],
          ...(submenu && (Object.keys(submenu)).length ? {
            submenuSrc:submenu.src
          } : {})
        });
      });
      setCustomMenus(menusList);
    }
  };


  const handleNotificationClick = () => {
    $('.div-block-163 .notification-trigger').on('click', function() {
      $('.div-block-163 .div-block-164').show();
    });
    //HIDING DROPDOWN WHEN CLICKING ELSEWHERE
    $(document).on('click', function(e) {
      if ($('.div-block-163 .div-block-164:visible') && !$(e.target).closest('.div-block-163 .notification-trigger').length) {
        $('.div-block-163 .div-block-164:visible').hide();
      }
    });
  };

  const handleMobileTriggerClick = () => {

    $('.mobile_nav_trigger').on('click', function() {
      $('.body .mobile_nav').show();
    });
    //HIDING DROPDOWN WHEN CLICKING ELSEWHERE
    $(document).on('click', function(e) {
      if ($('.body .mobile_nav:visible') && !$(e.target).closest('.mobile_nav_trigger, .mobile_nav .div-block-172').length) {
        $('.body .mobile_nav:visible').hide();
      }
    });
  };

  useEffect(() => {
    const tenant = getTenant();
    if (tenant && !STATIC_PAGES_ENABLED) {
      setLogoURL(`${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/logo/`);
    } else {
      setLogoURL(process.env.REACT_APP_IBL_DEFAULT_LOGO_URL);
    }
    STATIC_PAGES_ENABLED && CUSTOM_MENUS_ENABLED && handleCustomMenu();
  }, []);

  useDebounce(() => {
    handleNotificationClick();
    handleMobileTriggerClick();
  }, 200, []);

  return (
    <>
      <div className="nav">
        <div className="p_menu">
          <Link className="skills-logo w-inline-block w--current" to="/">
            <img
              alt=""
              className="image"
              loading="lazy"
              src={logoURL}
              style={{ height: process.env.REACT_APP_IBL_LOGO_HEIGHT || '57px' }}
            />
          </Link>
          <div className="div-block-231">
            {
              STATIC_PAGES_ENABLED && CUSTOM_MENUS_ENABLED && customMenus.map((menu, index) => (
                <React.Fragment key={index}>
                  <NavLink
                    className={({ isActive, isPending, isTransitioning }) =>
                      [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                    }
                    to={menu.link}
                  >
                    <>
                      <span>{menu.label}</span>
                      {
                        !!menu?.submenuSrc && (
                          <div className="html-submenu">
                            <iframe src={menu.submenuSrc} />
                          </div>
                        )
                      }
                    </>
                  </NavLink>
                </React.Fragment>

              ))
            }
            {
              !CUSTOM_MENUS_ENABLED && (
                <>
                  <NavLink
                    className={({ isActive, isPending, isTransitioning }) =>
                      [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                    }
                    to="/"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    className={({ isActive, isPending, isTransitioning }) =>
                      [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                    }
                    to="/profile/activity"
                  >
                    Profile
                  </NavLink>
                  <NavLink
                    className={({ isActive, isPending, isTransitioning }) =>
                      [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                    }
                    to="/recommended"
                  >
                    Recommended
                  </NavLink>
                  <NavLink
                    className={({ isActive, isPending, isTransitioning }) =>
                      [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                    }
                    to="/discover"
                  >
                    Discover
                  </NavLink>
                </>
              )
            }

          </div>
          <div className="prof_menu hidden" data-name="feed">
            Feed
          </div>
          <div className="form-block mobile w-form">
            <form
              className="form"
              data-name="Email Form"
              data-wf-element-id="76bb6082-7d02-39b3-1c24-730229d738a2"
              data-wf-page-id="6596e21ac435cac3c66baaea"
              id="email-form"
              method="get"
              name="email-form"
            >
              <div className="div-block">
                <img
                  alt=""
                  className="image-2"
                  loading="lazy"
                  src="/images/magnifying-glass_1magnifying-glass.png"
                />
              </div>
              <input
                className="text-field n_text w-input"
                data-name="Email 7"
                id="email-7"
                maxLength={256}
                name="email-7"
                placeholder="Search"
                required=""
                type="email"
              />
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <div className="nav-right">
            <div className="div-block-170">
              <Searchbar></Searchbar>
              {
                isJSON(appData?.current_tenant) && JSON?.parse(appData?.current_tenant)?.is_admin && (
                  <Link
                    className="link-block-32 ai-analytics w-inline-block"
                    to={analyticsURL}
                  >
                    <div className="text-block-309">AI Analytics</div>
                  </Link>
                )
              }
              {
                !appData?.userData &&
                <Link
                  className="link-block-32 ai-analytics w-inline-block"
                  to={loginURL}
                >
                  <div className="text-block-309">Log in</div>
                </Link>
              }
              {
                appData?.userData && (
                  <>
                    <div className="div-block-163">
                      <HiOutlineBell color={GLOBAL_COLORS.primary}
                                     className={'image-3 notification-trigger'} />
                      <img
                        alt=""
                        className="image-3h"
                        loading="lazy"
                        src="images/629f2ca4ae5641d483147dd9_bell.png"
                      />
                      <div className="div-block-164">
                        <div className="div-block-165">
                          <div className="text-block-104">Notifications</div>
                        </div>
                        <div className="div-block-166 empty-not">
                          <div className="text-block-105">There are no new notifications</div>
                        </div>
                        <div className="div-block-166 pop-not">
                          <div className="div-block-635"><img
                            src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bab26_cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1%20(1).jpeg"
                            loading="lazy" alt="" className="not-img" />
                            <div className="div-block-636">
                              <div className="text-block-373">Your subscription for <a href="#"
                                                                                       className="not-link"><span
                                className="product">Mentor</span></a> has been renewed
                              </div>
                              <div className="text-block-374">6 hours ago</div>
                            </div>
                          </div>
                          <div className="div-block-635"><img
                            src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bab26_cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1%20(1).jpeg"
                            loading="lazy" alt="" className="not-img" />
                            <div className="div-block-636">
                              <div className="text-block-373">Your subscription for <a href="#"
                                                                                       className="not-link"><span
                                className="product">Mentor</span></a> has started
                              </div>
                              <div className="text-block-374">30 days ago</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <IBLUserProfile style={{marginLeft:"20px"}} color={process.env.REACT_APP_FONT_COLOR}  />
                    {/*<Headerprofilemenue />*/}
                  </>
                )
              }

            </div>
            <div className="image-106 hide">
              <img
                alt=""
                className="sm"
                loading="lazy"
                src="images/ibl-logo-animated-2.gif"
              />
            </div>
          </div>
          <div className="mobile_nav_trigger">
            <div className="menicon"></div>
          </div>
        </div>
        <div className="l-nav"></div>
      </div>
      <div className="mobile_nav">
        <div className="mob_nav">
          <div className="div-block-171">
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [isActive ? 'active w--current' : '', 'dash_nav_item'].join(' ')
              }
              to="/"
            >
              Home
            </NavLink>
          </div>
          <div className="div-block-171">
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [isActive ? 'active w--current' : '', 'dash_nav_item'].join(' ')
              }
              to="/profile/activity"
            >
              Profile
            </NavLink>
          </div>
          <div className="div-block-171">
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [isActive ? 'active w--current' : '', 'dash_nav_item'].join(' ')
              }
              to="/recommended"
            >
              Recommended
            </NavLink>
          </div>
          <div className="div-block-171 last">
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [isActive ? 'active w--current' : '', 'dash_nav_item'].join(' ')
              }
              to="/discover"
            >
              Discover
            </NavLink>
          </div>
        </div>
        <div className="mob_nav d-block">
          <div className="div-block-172">
            <div className="div-block-163">
              <HiOutlineBell color={GLOBAL_COLORS.primary}
                             className={'image-3 notification-trigger'} />
              <img
                alt=""
                className="image-3h"
                loading="lazy"
                src="images/629f2ca4ae5641d483147dd9_bell.png"
              />
              <div className="div-block-164">
                <div className="div-block-165">
                  <div className="text-block-104">Notifications</div>
                </div>
                <div className="div-block-166 empty-not">
                  <div className="text-block-105">There are no new notifications</div>
                </div>
                <div className="div-block-166 pop-not">
                  <div className="div-block-635"><img
                    src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bab26_cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1%20(1).jpeg"
                    loading="lazy" alt="" className="not-img" />
                    <div className="div-block-636">
                      <div className="text-block-373">Your subscription for <a href="#"
                                                                               className="not-link"><span
                        className="product">Mentor</span></a> has been renewed
                      </div>
                      <div className="text-block-374">6 hours ago</div>
                    </div>
                  </div>
                  <div className="div-block-635"><img
                    src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bab26_cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1%20(1).jpeg"
                    loading="lazy" alt="" className="not-img" />
                    <div className="div-block-636">
                      <div className="text-block-373">Your subscription for <a href="#"
                                                                               className="not-link"><span
                        className="product">Mentor</span></a> has started
                      </div>
                      <div className="text-block-374">30 days ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="div-block-163">
                            <img
                                alt=""
                                className="image-3"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 22px, 100vw"
                                src="images/setting.png"
                                srcSet="images/setting-p-500.png 500w, images/setting.png 512w"
                            />
                            <img
                                alt=""
                                className="image-3h"
                                loading="lazy"
                                src="images/62a1d584bc8cea2ba5c40d38_setting-p-500.png"
                            />
                            <div className="div-block-164">
                                <div className="div-block-165">
                                    <div className="text-block-104">Settings</div>
                                </div>
                                <a
                                    className="link-block-11 w-inline-block"
                                    href="https://ibl-skills.webflow.io/profile#pp"
                                >
                                    <div className="text-block-106">Profile Settings</div>
                                </a>
                            </div>
                        </div>
                        <div className="div-block-167">
                            <img
                                alt=""
                                className="image-4"
                                loading="lazy"
                                src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg"
                            />
                            <img
                                alt=""
                                className="image-5"
                                loading="lazy"
                                src="images/629f2d70ada2d5ad683a0982_down-arrow_1629f2d70ada2d5ad683a0982_down-arrow.png"
                            />
                            <div className="div-block-164 profile">
                                <div className="div-block-165">
                                    <div className="text-block-104">Ashley Miller</div>
                                    <div className="text-block-104 em">ashley@gmail.com</div>
                                </div>
                                <a
                                    className="link-block-11 w-inline-block"
                                    href="profile/activity.html"
                                >
                                    <div className="text-block-106">Overview</div>
                                </a>
                                <a
                                    className="link-block-11 w-inline-block"
                                    href="public-profile.html"
                                >
                                    <div className="text-block-106">Profile</div>
                                </a>
                                <a className="link-block-11 w-inline-block" href="#">
                                    <div className="text-block-106">Log Out</div>
                                </a>
                            </div>
                        </div>*/}
            <IBLUserProfile style={{marginLeft:"20px"}} color={process.env.REACT_APP_FONT_COLOR} />
            {/*<Headerprofilemenue />*/}
          </div>
        </div>
      </div>
    </>
  )
    ;
};

export default Header;
