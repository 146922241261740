import Coursebox from '../../components/Coursebox/Coursebox';
import React from 'react';
import useRecommended from '../../hooks/useRecommended';
import {IBLSpinLoader, NoItemView} from '@iblai/ibl-web-react-common';

const RecommendedViewContent = () => {
  const [recommendedCoursesData, recommendedCoursesDataLoaded] =
    useRecommended();
  return (
    <span>
      <div className="panels">
        <div className="panels_cont">
          <div className="panel-browse panel">
            <div className="browse">
              <div className="browse_panel active" data-name="browse">
                <div className="browse_cont">
                  <div className="browse_left" style={{ display: 'none' }}>
                    <div className="prof_box browse">
                      <div className="text-block sec_title ex_co" no-mapping="">
                        Add Content
                      </div>
                      <div className="text-block sec_title fb" no-mapping="">
                        Filter By
                      </div>
                    </div>
                    <div className="prof_box grey s hidden">
                      <div className="div-block-376">
                        <img
                          alt=""
                          className="image-127"
                          loading="lazy"
                          src="images/plus-2_1plus-2.png"
                        />
                      </div>
                      <div className="text-block sec_title ex_co amc">
                        Add My Content
                      </div>
                    </div>
                  </div>

                  <div className="br_panel" data-name="top" style={{ width: '100%' }}>
                    <div className="text-block-308">Recommended for Me</div>
                    {!recommendedCoursesDataLoaded ? (
                      <IBLSpinLoader size={40} containerHeight={'400px'} />
                    ) :
                        Array.isArray(recommendedCoursesData?.courses) && recommendedCoursesData?.courses.length > 0 ?
                            (
                                <div className="div-block-430 sl pathway_grid">
                                  <Coursebox
                                      userCourses={recommendedCoursesData?.courses || []}
                                      userCoursesLoaded={recommendedCoursesDataLoaded}
                                      linkPrefix={'/courses/'}
                                  ></Coursebox>
                                </div>
                            )
                            :
                            <NoItemView displayMessage={"No Recommended Courses Available"} />
                        }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default RecommendedViewContent;
