import { useEffect, useState } from 'react';
import { getDiscoverItemsAPI } from './utils/discover';
import { useLocation } from 'react-router-dom';

function useDiscover() {
  const [discoverItems, setDiscoverItems] = useState([]);
  const [discoverLoaded, setDiscoverLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const location = useLocation();

  const queryParam = new URLSearchParams(location.search);
  const search = queryParam.get('q');

  useEffect(() => {
    getDiscoverItemsAPI(discoverCallback, 1, null, search);
  }, [search, page]);

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };
  return [discoverItems, discoverLoaded, setDiscoverItems, setDiscoverLoaded];
}

export default useDiscover;
