import React from 'react';
import { Link } from 'react-router-dom';
import { getRandomImage } from '../../hooks/helpers';

const handleImageError = (e) => {
  e.target.src = getRandomImage();
};
const Generateslide = (props) => {
  const imageUrl = process.env.REACT_APP_IBL_LMS_URL + props.courseImagePath;

  return (
    <div className="slide w-slide" key={props.courseID}>
      <div className="slide div-block-430">
        <Link
          className="link-block-7 mob w-inline-block"
          to={`/courses/${props.courseID}`}
          id="w-node-b1639951-3f1a-233f-4ae1-b774073c1095-c66baad7"
        >
          <div
            className="item-box"
            id="w-node-b1639951-3f1a-233f-4ae1-b774073c1096-c66baad7"
          >
            <div className="div-block-22">
              <img
                alt=""
                className="image-11"
                loading="lazy"
                onError={handleImageError}
                sizes="(max-width: 991px) 100vw, (max-width: 1279px) 22vw, 234px"
                src={imageUrl}
              />
              <div className="label c">
                <div className="text-block-124">COURSE</div>
              </div>
            </div>
            <div className="item-body">
              <div className="div-block-23">
                <div className="div-block-24">
                  <img
                    alt=""
                    className="image-18"
                    loading="lazy"
                    src="images/ibm.png"
                  />
                </div>
                <div className="text-block-16">IBM</div>
                <div className="div-block-28">
                  <div className="text-block-20">120 Skill Coins</div>
                </div>
              </div>
              <div className="text-block-9">{props.courseName}</div>
              <div className="div-block-25">
                <div className="text-block-17">
                  <span className="skill_span">Computer Programming</span>
                  <span className="skill_span">Python Libraries</span>
                  <span className="skill_span last">Python Programming</span>
                </div>
              </div>
              <div className="div-block-26">
                <img
                  alt=""
                  className="image-19"
                  loading="lazy"
                  sizes="100vw"
                  src="images/Screenshot-2022-06-07-at-7.25.09-PM.png"
                  srcSet="images/Screenshot-2022-06-07-at-7.25.09-PM-p-500.png 500w, images/Screenshot-2022-06-07-at-7.25.09-PM-p-800.png 800w, images/Screenshot-2022-06-07-at-7.25.09-PM-p-1080.png 1080w, images/Screenshot-2022-06-07-at-7.25.09-PM.png 1343w"
                />
                <div className="text-block-18">(1k+)</div>
              </div>
              <div className="div-block-27">
                <div className="text-block-19">
                  <strong className="bold-text-2"> </strong>
                  <span className="bullet">
                    <strong className="bold-text-2"></strong>
                  </span>
                  <strong className="bold-text-2"> </strong>
                </div>
                <div className="item_options">
                  <div className="item_options_triger">
                    <img
                      alt=""
                      className="image-20"
                      loading="lazy"
                      src="images/menu_1menu.png"
                    />
                  </div>
                  <div className="options_div">
                    <div className="text-block-103">Add to Pathway</div>
                    <div className="text-block-103">Save for Later</div>
                    <div className="text-block-103">Dismiss</div>
                    <div className="text-block-103">Share</div>
                    <div className="text-block-103">Create a Pathway</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Generateslide;
