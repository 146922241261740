import React, { useState } from 'react';
import './Contentfilterbox.css';

const Contentfilterbox = ({
  discoverItems,
  selectedTerms,
  setSelectedTerms,
  facetHandler,
}) => {
  const convertFacetData = (facetData) => {
    const facetList = [];
    for (const facetName in facetData.facet) {
      if (facetData.facet.hasOwnProperty(facetName)) {
        const facetInfo = facetData.facet[facetName];
        const facetObject = {
          name: facetName,
          total: facetInfo.total,
          terms: Object.entries(facetInfo.terms).map(([term, count]) => ({
            term,
            count,
          })),
        };

        if (facetObject.terms.length > 0) {
          // Only add facets with terms
          facetList.push(facetObject);
        }
      }
    }
    return facetList;
  };

  const [facetVisibility, setFacetVisibility] = useState({});
  const [filterText, setFilterText] = useState('');

  const handleFacetClick = (facetName) => {
    setFacetVisibility((prevVisibility) => ({
      ...prevVisibility,
      [facetName]: !prevVisibility[facetName],
    }));
  };

  const handleTermClick = (facetName, term) => {
    setSelectedTerms((prevSelectedTerms) =>
      prevSelectedTerms.includes(term)
        ? prevSelectedTerms.filter((selectedTerm) => selectedTerm !== term)
        : [...prevSelectedTerms, term]
    );
    facetHandler();
  };

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
  };

  return (
    <>
      {/* ... other content */}
      {convertFacetData(discoverItems).map((facet, index) => (
        <div className="f_box" key={index}>
          <div className="f_row" onClick={() => handleFacetClick(facet.name)}>
            <div prop-data-value-label="Type" prop-data-names="label">
              {facet.name}
            </div>
            <img
              src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bac9f_plus.png"
              loading="lazy"
              alt=""
              className="img_v"
              style={{
                display: facetVisibility[facet.name] ? 'none' : 'inline-block',
              }}
            />
            <img
              src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bacc9_minus-sign.png"
              loading="lazy"
              alt=""
              className="img_h"
              style={{
                display: facetVisibility[facet.name] ? 'inline-block' : 'none',
              }}
            />
          </div>
          <div
            className="form-block filter w-form"
            style={{ display: facetVisibility[facet.name] ? 'block' : 'none' }}
          >
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form filter"
              data-wf-page-id="6596e21ac435cac3c66baade"
              data-wf-element-id="7367745f-8af8-edc7-1e44-e178d595e2a9"
              aria-label="Email Form"
            >
              <div className="div-block-9">
                <input
                  className="text-field w-input"
                  maxLength={256}
                  name="filter-facet-terms"
                  data-name="Email 2"
                  placeholder="Filter"
                  type="text"
                  id="filter-facet-terms"
                  value={filterText}
                  onChange={handleInputChange}
                />
                <div className="div-block">
                  <img
                    src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66baba3_loupe.png"
                    loading="lazy"
                    alt=""
                    className="image-2"
                  />
                </div>
              </div>
              {/* Loop through the facet items and create checkboxes */}
              {facet.terms
                .filter((term) =>
                  term.term.toLowerCase().includes(filterText.toLowerCase())
                )
                .map((term, termIndex) => (
                  <label key={termIndex} className="w-checkbox checkbox-field">
                    <input
                      type="checkbox"
                      id={`checkbox-${index}-${termIndex}`}
                      name={`checkbox-${index}-${termIndex}`}
                      data-name={`Checkbox ${index}-${termIndex}`}
                      className="w-checkbox-input checkbox"
                      checked={selectedTerms.includes(term.term)}
                      onChange={() => handleTermClick(facet.name, term.term)}
                    />
                    <span
                      className="checkbox-label w-form-label"
                      htmlFor={`checkbox-${index}-${termIndex}`}
                    >
                      {`${term.term} (${term.count})`}
                    </span>
                  </label>
                ))}
            </form>
          </div>
        </div>
      ))}
    </>
  );
};

export default Contentfilterbox;
