import CourseIframeSecondaryMenu from '../../../components/CourseIframeSecondaryMenu';
import CourseIframeSideMenue from '../../../components/CourseIframeSideMenue';
import CourseIframeSequenceMenu from '../../../components/CourseIframeSequenceMenu';
import { ColorRing } from 'react-loader-spinner';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCourseIframe } from '@iblai/ibl-web-react-common';
import './CourseIframeCourseContentView.css';
import { getParameterByName } from '../../../hooks/helpers';
import { getFirstAvailableUnit } from '../../../hooks/utils/iframe';

const CourseIframeCourseContentView = () => {
  const { courseID } = useParams();
  const [
    courseDetails,
    courseLoaded,
    courseCompletion,
    courseCompletionLoaded,
    courseGradeProgress,
    courseGradeProgressLoaded,
  ] = useCourseIframe(courseID);
  const [edxIframe, setEdxIframe] = useState('');
  const [edxIframeLoading, setEdxIframeLoading] = useState(false);
  const [unitIdFromUrl, setUnitIdFromUrl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);

  function isCourseContentAvailable(courseDetails) {
    const unitId = getParameterByName('unit_id') || getFirstAvailableUnit(courseDetails)?.id;
    if (!unitId) return false;  // Return false if no unitId is available

    // Check if any childItem within any item has the id matching unitId
    const hasUnitId = courseDetails?.children?.some(item =>
        Array.isArray(item.children) && item.children.some(childItem =>
          childItem.id === unitId,
        ),
    );

    return !hasUnitId;
  }


  const changeIframeLoadingStatus = (status) => {
    setEdxIframeLoading(status);
  };

  const handleCollapseClick = (status) => {
    setIsExpanded(status);
  };
  return courseLoaded ? (
    courseDetails && courseDetails?.children ? (
      <>
        <CourseIframeSecondaryMenu courseData={courseDetails} />
        <CourseIframeSideMenue
          courseData={courseDetails}
          setEdxIframe={setEdxIframe}
          changeIframeLoadingStatus={changeIframeLoadingStatus}
          unitIdFromUrl={unitIdFromUrl}
          setUnitIdFromUrl={setUnitIdFromUrl}
          handleMenuCollapse={handleCollapseClick}
        />
        <div className="inside_page" style={{ marginLeft: isExpanded ? '299px' : '40px' }}>
          {courseLoaded && (
            <CourseIframeSequenceMenu
              courseData={courseDetails}
              setEdxIframe={setEdxIframe}
              changeIframeLoadingStatus={setEdxIframeLoading}
              setUnitIdFromUrl={setUnitIdFromUrl}
            />
          )}
          <div className="html-embed-19 w-embed w-iframe">
            {edxIframeLoading && isCourseContentAvailable(courseDetails) && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={[
                    '#4a78b8', '#6cafe1', '#4a78b8', '#6cafe1',
                    '#4a78b8', '#6cafe1', '#4a78b8', '#6cafe1',
                    '#4a78b8', '#6cafe1',
                  ]}
                />
              </div>
            )}
            {isCourseContentAvailable(courseDetails) ? (
              <iframe
                id="edx-iframe"
                title="Course InnerWare"
                sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads"
                frameBorder={0}
                src={edxIframe}
                style={{ width: '100%', height: 'calc(100vh - 100px - 62px)' }}
                allowFullScreen=""
                onLoad={() => setEdxIframeLoading(false)}
              ></iframe>
            ) : (
              <>
              <div style={{ textAlign: 'center' }} >
                There is no content here
                </div>
                </>
                )
              }
              </div>
              </div>
              </>

              ) : (
              <>
              <style
              dangerouslySetInnerHTML={{
              __html:
              '\n.no-course-data-box {\n    margin-top: 24px;\n    padding: 24px;\n    border-width: 1px;\n    border-style: solid;\n    border-color: rgb(221, 225, 230);\n    border-radius: 4px;\n    height: 238px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    margin-left: 24px;\n    margin-right: 24px;\n}\n\n.no-course-flex img {\n    display: block;\n    margin: 0 auto;\n    margin-bottom: 16px;\n}\n\n',
            }}
          />

          <div className="no-course-data-cont">
            <div className="no-course-data-box">
              <div className="no-course-flex">
                <img
                  style={{ width: 40, height: 40 }}
                  src="/images/open-book.png"
                  alt=""
                />
                <div>No content for this course yet.</div>
              </div>
            </div>
          </div>
        </>
        )
        ) : (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={[
              '#4a78b8',
              '#6cafe1',
              '#4a78b8',
              '#6cafe1',
              '#4a78b8',
              '#6cafe1',
              '#4a78b8',
              '#6cafe1',
              '#4a78b8',
              '#6cafe1',
            ]}
          />
        </div>
        );
        };

        export default CourseIframeCourseContentView;
