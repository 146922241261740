import React from 'react';
import './Monthlyactivitybox.css';
import { secondsToHoursAndMinutes } from '../../hooks/helpers';
import {GLOBAL_COLORS, IBLSpinLoader} from '@iblai/ibl-web-react-common';
import { GoClockFill } from "react-icons/go";
import { IoIosPlayCircle } from "react-icons/io";
import { MdFactCheck } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";




const Monthlyactivitybox = (props) => {
  return (
    <div className="pr_g f lf ma home mgr0">
      <div className="div-block-14">
        <div className="skm">Monthly Activity</div>
      </div>
      <div>
        {!props?.userMonthlyActivityDataLoaded ? <IBLSpinLoader size={40}/> :  (
          <>
            <div className="div-block-20">
              <div className="div-block-21">
                  <GoClockFill className={"image-17"} color={GLOBAL_COLORS.icon_color}/>
                {/*<img
                  alt=""
                  className="image-17"
                  loading="lazy"
                  src="images/time_1time.png"
                  width={12}
                />*/}
              </div>
              <div className="text-block-14">Time Spent</div>
              <div className="text-block-15">
                {secondsToHoursAndMinutes(
                  props?.userMonthlyActivityData?.data?.total_time_spent
                )}
              </div>
            </div>
            <div className="div-block-20">
              <div className="div-block-21">
                  <IoIosPlayCircle className={"image-17"} color={GLOBAL_COLORS.icon_color} />
                {/*<img
                  alt=""
                  className="image-17"
                  loading="lazy"
                  src="images/play-button_1play-button.png"
                />*/}
              </div>
              <div className="text-block-14">Watched Videos</div>
              <div className="text-block-15">
                {props?.userMonthlyActivityData?.data?.total_videos}
              </div>
            </div>
            <div className="div-block-20">
              <div className="div-block-21">
                  <MdFactCheck className={"image-17"} color={GLOBAL_COLORS.icon_color} />
                {/*<img
                  alt=""
                  className="image-17"
                  loading="lazy"
                  src="images/checklist_1checklist.png"
                />*/}
              </div>
              <div className="text-block-14">Assessments</div>
              <div className="text-block-15">
                {props?.userMonthlyActivityData?.data?.total_assessments}
              </div>
            </div>
            <div className="div-block-20">
              <div className="div-block-21">
                  <FaCheck className={"image-17"} color={GLOBAL_COLORS.icon_color} />
                {/*<img
                  alt=""
                  className="image-17"
                  loading="lazy"
                  sizes="(max-width: 991px) 12px, 1vw"
                  src="images/check_2.png"
                  srcSet="images/check_2-p-500.png 500w, images/check_2.png 512w"
                />*/}
              </div>
              <div className="text-block-14">Course Completions</div>
              <div className="text-block-15">
                {props?.userMonthlyActivityData?.data?.course_completions}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="div-block-374 hidden">
        <a
          className="link-block-4 w-inline-block"
          href="https://ibl-skills-v7.webflow.io/profile"
        >
          <div className="text-block-217">All Activity</div>
          <img
            alt=""
            className="image-125 rc"
            loading="lazy"
            src="images/chevron_1chevron.png"
          />
        </a>
      </div>
    </div>
  );
};

export default Monthlyactivitybox;
