import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as SliderModule from 'react-slick';

import Generateslide from './Generateslide/Generateslide';

const SliderComponent = SliderModule?.default ? SliderModule.default : SliderModule;

const Courseslider = ({ courses, handlePrev, handleNext, sliderRef, sliderSettings }) => {
  return (
    <div className="br_row">
      <div
        data-delay={4000}
        data-animation="slide"
        className="slider-9 w-slider"
        data-autoplay="false"
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="false"
        data-autoplay-limit={0}
        data-nav-spacing={3}
        data-duration={500}
        data-infinite="true"
      >
        <div className="slider-9 w-slider" data-autoplay="false">
          <div className="mask w-slider-mask">
            <SliderComponent ref={sliderRef} {...sliderSettings}>
              {courses?.map((courseData, index) => (
                <Generateslide
                  key={index}
                  slideKey={index}
                  courseImagePath={courseData?.metadata?.course_image_asset_path}
                  courseID={courseData.course?.course_id}
                  courseName={courseData.course?.course_name}
                />
              ))}
            </SliderComponent>
          </div>
        </div>
        <div
          className="left-arrow w-slider-arrow-left"
          onClick={handlePrev}
        >
          <img
            src="/images/prev.png"
            loading="lazy"
            alt=""
            className="image-160"
          />
        </div>
        <div
          className="right-arrow-8 w-slider-arrow-right"
          onClick={handleNext}
        >
          <img
            src="/images/next.png"
            loading="lazy"
            alt=""
            className="image-176"
          />
        </div>
        <div className="slide-nav-4 w-slider-nav w-round" />
      </div>
    </div>
  );
};

export default Courseslider;
