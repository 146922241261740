import React from 'react';
import './Coursebox.css';
import {Link} from 'react-router-dom';
import {getRandomImage, NoItemView} from '@iblai/ibl-web-react-common';

const Coursebox = ({
                       userCoursesLoaded,
                       userCourses,
                       linkPrefix = '/profile/courses/',
                   }) => {
    // TODO : add different course images on load

    return (
        <>
            {userCoursesLoaded && (
                Array.isArray(userCourses) && userCourses.length > 0 && (
                        userCourses.map((course, index) => (
                            <div
                                key={index}
                                className="item-box path_box"
                                id={`w-node-_9d122df3-5436-acb4-d16f-30b76ec43f9d-27264aa9`}
                            >
                                <Link
                                    className="link-block-31 w-inline-block"
                                    to={`${linkPrefix}${course._source?.course_id || course?.course_id}`}
                                >
                                    <div className="div-block-411">
                                        <img
                                            alt="course-image"
                                            className="image-155"
                                            loading="lazy"
                                            sizes="(max-width: 767px) 234px, (max-width: 991px) 295.734375px, (max-width: 1279px) 21vw, 226.5px"
                                            src={getRandomImage()}
                                        />
                                        <div className="label c">
                                            <div className="text-block-294">COURSE</div>
                                        </div>
                                    </div>
                                    <div className="item-body">
                                        <div className="div-block-417">
                                            <div className="div-block-461">
                                                <img
                                                    alt=""
                                                    className="image-167"
                                                    loading="lazy"
                                                    sizes="100vw"
                                                    src="images/google.png"
                                                    srcSet="images/google-p-500.png 500w, images/google.png 512w"
                                                />
                                            </div>
                                            <div className="text-block-235">Google</div>
                                            <div className="div-block-523">
                                                <div className="text-block-233">120 Skill Coins</div>
                                            </div>
                                        </div>
                                        <div className="text-block-225">
                                            {course?._source?.name || course?.name}
                                        </div>
                                        <div className="div-block-470">
                                            <div className="text-block-273">
                                                <span className="skill_span">Computer Programming</span>
                                                <span className="skill_span">Python Libraries</span>
                                                <span className="skill_span last">
                        Python Programming
                      </span>
                                            </div>
                                        </div>
                                        <div className="div-block-387">
                                            <img
                                                alt=""
                                                className="image-184"
                                                loading="lazy"
                                                sizes="100vw"
                                                src="images/Screenshot-2022-06-07-at-7.25.09-PM.png"
                                                srcSet="images/Screenshot-2022-06-07-at-7.25.09-PM-p-500.png 500w, images/Screenshot-2022-06-07-at-7.25.09-PM-p-800.png 800w, images/Screenshot-2022-06-07-at-7.25.09-PM-p-1080.png 1080w, images/Screenshot-2022-06-07-at-7.25.09-PM.png 1343w"
                                            />
                                            <div className="text-block-241">(1k+)</div>
                                        </div>
                                        <div className="div-block-389">
                                            <div className="text-block-287">
                                                <strong className="bold-text-7 p"> </strong>
                                                <span className="bullet">
                        <strong className="bold-text-7"></strong>
                      </span>
                                                <strong className="bold-text-7"></strong>
                                            </div>
                                            <div className="item_options">
                                                <div className="item_options_triger">
                                                    <img
                                                        alt=""
                                                        className="image-182"
                                                        loading="lazy"
                                                        src="images/menu_1menu.png"
                                                    />
                                                </div>
                                                <div className="options_div">
                                                    <div className="text-block-238">Add to Pathway</div>
                                                    <div className="text-block-238">Save for Later</div>
                                                    <div className="text-block-238">Dismiss</div>
                                                    <div className="text-block-238">Share</div>
                                                    <div className="text-block-238">Create a Pathway</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    )
            )}
        </>
    );
};

export default Coursebox;
