import axios from 'axios';
import { api } from '@iblai/ibl-web-react-common';

export function orgSearch(data, callback) {
  let url = `${getIBLSEARCHURL()}/api/search/search/${data}`;
  axios
    .get(url)
    .then((response) => {
      // Handle successful response
      callback(response.data);
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
}

export function getSkillDescription(skillLevel) {
  switch (skillLevel) {
    case 1:
      return 'Can complete narrow and limited tasks requiring basic skills with direct supervision or guidance.';
    case 2:
      return 'Handles routine assignments with some confidence; occasional assistance may be needed.';
    case 3:
      return 'Capable of managing varied tasks; understands nuances but may seek guidance for complexities.';
    case 4:
      return 'Skilled at handling complex scenarios; regularly assists and mentors others in the domain.';
    case 5:
      return 'Demonstrates mastery in tasks, often pioneering solutions; consistently leads by example.';
    default:
      return ''; // Handle invalid skill levels
  }
}

export function getUserId() {
  let userData = JSON.parse(localStorage.getItem('userData'));
  return userData.user_id;
}

export function getUserName() {
  let userData = JSON.parse(localStorage.getItem('userData'));
  return userData.user_nicename;
}

export function getTenant() {
  // return "main"
  return localStorage.getItem('tenant');
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  var parameterValue = decodeURIComponent(results[2].replace(/\+/g, ' '));
  parameterValue = parameterValue.replace(/ /g, '+'); // Replace spaces with "+"
  return parameterValue;
}

export function credentialImageURL(credential) {
  return credential.credentialDetails.iconImage || '/images/default_badge.png';
}

export function getRandomImage() {
  let img_url = '/images/courses/';
  let images_array = [
    'c1s.jpeg',
    'c2s.jpeg',
    'c3s.jpeg',
    'c4s.jpeg',
    'c5s.jpeg',
    'c6s.jpeg',
    'c7s.jpeg',
    'c8s.jpeg',
  ];
  let random = Math.floor(Math.random() * images_array.length);
  return img_url + images_array[random];
}

export function secondsToHoursAndMinutes(_seconds) {
  const hours = Math.floor(_seconds / 3600);
  const minutes = Math.floor((_seconds % 3600) / 60);
  const seconds = _seconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}`;
}

export function formatReadableDate(inputDate) {
  // Parse the input date string
  const dateObj = new Date(inputDate);

  // Check if the date is valid
  if (isNaN(dateObj.getTime())) {
    return NaN;
  }

  // Define options for formatting
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // Format the date
  return dateObj.toLocaleDateString('en-US', options);
}

// TODO : add process.env
export function getIBLSEARCHURL() {
  return process.env.REACT_APP_IBL_SEARCH_URL;
}

export function getYearDateRange() {
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var currentMonth = currentDate.getMonth();

  var startMonth = (currentMonth + 12) % 12;
  var endMonth = currentMonth;
  var yearOffset = Math.floor((currentMonth - 12) / 12);
  var startYear = currentYear + yearOffset;

  var startDate = new Date(startYear, startMonth, 1);
  var endDate = new Date(currentYear, endMonth + 1, 0);

  return {
    start: startDate.toISOString().split('T')[0],
    end: endDate.toISOString().split('T')[0],
  };
}

export function formatEarnedOnDate(stringDate) {
  let options = { day: 'numeric', year: 'numeric', month: 'short' };
  let date = new Date(stringDate);
  return date.toLocaleDateString('en-US', options);
}

export function getImageUrl(url) {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  if (urlRegex.test(url)) {
    return url;
  }

  return '/images/default_avatar.png';
}

export function parseLocalStorageData(key) {
  const jsonString = localStorage.getItem(key);

  if (!jsonString) {
    console.error(`No data found in localStorage for key: ${key}`);
    return null;
  }

  try {
    const parsedData = JSON.parse(jsonString);
    return parsedData;
  } catch (error) {
    console.error(`Error parsing JSON data for key: ${key}`, error);
    return null;
  }
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const handleLogout = () => {
  api.iblwebauth.logout(
    JSON.stringify({ username: getUserName() }),
    (logoutUrl) => {
      window.location.href = `${logoutUrl}?redirect_to=${process.env.REACT_APP_IBL_SPA_AUTH_URL}/logout?redirect-to=${window.location.origin}/logout`;
    }
  );
};

export const getAnalyticsURL = () => {
  return process.env.REACT_APP_IBL_SPA_ANALYTICS_URL || '#';
};

export const isStripeEnabled = () => {
  return process.env.REACT_APP_IBL_ENABLE_STRIPE === 'true';
};

export const isStaticPagesEnabled = () => {
  return process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED === 'true';
};

export const isCustomMenusEnabled = () => {
  return (
    process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_USE_CUSTOM_MENUS === 'true'
  );
};

export const isCustomFooterMenusEnabled = () => {
  return (
    process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_USE_CUSTOM_FOOTER_MENUS ===
    'true'
  );
};

export const isCustomSubMenusEnabled = () => {
  return (
    process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_USE_CUSTOM_HTML_SUBMENUS ===
    'true'
  );
};

export const getLoginURL = () => {
  return `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
};
