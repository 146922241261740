import React from 'react';
import sendIcon from "../../images/copilot-launcher-send.svg"
import $ from "jquery"
import {useDebounce} from "../../hooks/useDebounce";


const CopilotLauncher = () => {
    const handleCopilotLaunch = () => {
        $('.mentor-launcher-block .user-prompt-input-field').on('focus', function(){
            $('.body .full').addClass("w-bar")
            $('.mentor-closer-block').removeClass("hidden")
            $(this).blur();
            const iframedCopilot =
              document.querySelector('#iframed-mentor');
            //const slug = getPageSlug();
            const data = {
                focusPrompt: 1,
            };
            const message = JSON.stringify(data);
            iframedCopilot.contentWindow?.postMessage(message, '*');
        })
    }
    useDebounce(handleCopilotLaunch, 200, [])

    return (
        <div className="w-layout-hflex mentor-launcher-block">
            <div className="form-block-28 w-form">
                <form method="get" name="wf-form-user-prompt" data-name="user-prompt" id="wf-form-user-prompt" prop-events-value-onsubmit="handleUserPromptFormSubmit" prop-events-names="onSubmit" className="form-18" data-wf-page-id="6596e21ac435cac3c66baad7" data-wf-element-id="db0170d3-cee4-1666-f4d9-caeb4a4ff1c1">
                    <div className="input-container">
                        <div className="user-input-field-block"><input className="user-prompt-input-field black-bg-dark-mode whiten-border-in-dark-mode whiten-in-dark-mode w-input" maxLength="256" name="name" data-name="Name" placeholder="Enter a Prompt Here" type="text" id="name"/>
                            <div className="div-block-637">
                                <div prop-events-value-onclick="handlePromptFileUploadBtnClick" prop-events-names="onClick" data-w-id="db0170d3-cee4-1666-f4d9-caeb4a4ff1d8" className="user-input-actions-container upload-file-input-action-container greyish-bg-in-darkmode all-whiten-img-on-hover-in-dark-mode">
                                    <div className="tooltip-2 tooltip-user-file-upload">Add Files (5 max, 10Mb each) Accepts pdf, txt, csv, etc.</div><img src="https://uploads-ssl.webflow.com/650305782bc61751e5765211/650305782bc61751e5765225_paperclip.svg" loading="lazy" alt="" className="image-6"/>
                                </div>
                                <div prop-events-value-onclick="handleMicrophoneBtnClick" prop-events-names="onClick" data-w-id="db0170d3-cee4-1666-f4d9-caeb4a4ff1dc" className="user-input-actions-container greyish-bg-in-darkmode user-input-microphone-btn"><img src="images/microphone-svgrepo-com.svg" loading="lazy" alt="" className="image-6 whiten-in-dark-mode"/>
                                    <div className="tooltip-2 tooltip-microphone">Use the microphone</div>
                                </div>
                                <div prop-events-value-onclick="handleAIAudioCallBtnClick" prop-events-names="onClick" data-w-id="db0170d3-cee4-1666-f4d9-caeb4a4ff1e0" className="user-input-actions-container greyish-bg-in-darkmode user-input-audio-chat-btn"><img src="images/phone-calling-svgrepo-com.svg" loading="lazy" alt="" className="image-6 whiten-in-dark-mode ai-audio-chat-icon"/>
                                    <div className="tooltip-2 tooltip-microphone">Make AI Audio Chat</div>
                                </div>
                            </div>
                        </div>
                    </div><input type="submit" data-wait="Please wait..." className="submit-button w-button" value=""/>
                    <div prop-events-value-onclick="handleUserPromptFormSubmit" prop-events-names="onClick" className="input-submit-container"><img alt="" loading="lazy" src={sendIcon} className="input-submit-icon whiten-in-dark-mode"/></div>
                </form>
                <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
        </div>
    );
};

export default CopilotLauncher;