import { useEffect, useRef, useState } from 'react';
import { getRecommendedCourses } from './utils/recommended';

function useRecommended() {
  const [recommendedCoursesData, setRecommendedCoursesData] = useState([]);
  const [recommendedCoursesDataLoaded, setRecommendedCoursesDataLoaded] =
    useState(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getRecommendedCourses(getRecommendedCoursesCallback);
      hasFetchedData.current = true;
    }
  }, []);

  const getRecommendedCoursesCallback = (data) => {
    setRecommendedCoursesData(data);
    setRecommendedCoursesDataLoaded(true);
  };

  return [recommendedCoursesData, recommendedCoursesDataLoaded];
}

export default useRecommended;
