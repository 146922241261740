import React from 'react';
import {FaTools} from "react-icons/fa";
import {GLOBAL_COLORS} from "@iblai/ibl-web-react-common";

const NoItemView = ({ displayMessage, imageUrl, displayPadding }) => {
  return (
    <div
      className="no-skills-view earned-skills"
      style={{
        display: 'flex',
        marginBottom: '10px',
        border: displayPadding ? '1px solid #ccc' : '0',
      }}
    >
        <FaTools color={GLOBAL_COLORS.icon_color} className={"parent-img"} style={{ height: '36px', width:"36px", marginBottom: '12px' }} />
      {/*<img
        src={imageUrl}
        className="parent-img"
        alt="Item"
        style={{ height: '36px', marginBottom: '12px' }}
      />*/}
      <div>
        <div style={{ textAlign: 'center' }}>{displayMessage}</div>
      </div>
    </div>
  );
};

export default NoItemView;
