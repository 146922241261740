import { orgSearch } from '../helpers';

export function getDiscoverItemsAPI(
  callback,
  page,
  selectedFacets,
  context
) {
  const indexes = [
    { _index: 'ibl-dm.public.dl_catalog_app_course' },
    { _index: 'ibl-dm.public.dl_catalog_app_pathway' },
    { _index: 'ibl-dm.public.dl_catalog_app_program' },
  ];

  const indicesParamName = context ? 'multiple_indices_context' : 'multiple_indices';

  const data = `?${indicesParamName}=${JSON.stringify(indexes)}&page=${page}${selectedFacets?.length > 0 ? `&facet_search=${JSON.stringify({ selectedFacets })}` : ''}${context ? '&context=' + context : ''}`;

  orgSearch(data, (response) => {
    callback(response);
  });
}
